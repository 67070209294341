.img-ab-sc img {
  width: 100%;
  border-radius: 5px;
  height: auto;
  object-fit: contain;
}
.img-ab-sc {
  padding: 0px 15px;
}
.abt-r-txt-c {
  padding: 0px 15px;
  color: white;
}
.title-2-abt{
    margin-top: 70px;
    margin-bottom: 20px;
    color: white;
}
.lading-abut-top-c{
    margin-top: 30px;
}
