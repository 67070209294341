@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
:root {
  --blue1: #e3f2fd;
  --bgBlack2: #ffffff14;
  --blue2: rgb(41 62 109);
}
#root {
  height: 100vh;
}
body {
  background-color: black;
  font-family: "Inter";
  overflow-y: clip;

  /* background-image: url("./images/blue-abstract-blue-background-gradient-abstract-3840x2160-8985.jpg"); */
}
.ant-layout-content {
  height: 100%;
  overflow-y: auto;
}
.ant-layout {
  height: 100%;
}
.icons-top-story {
  display: flex;
}
.icons-top-story div {
  background-color: var(--blue2);
  color: rgb(255, 255, 255);
  margin: 0px 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons-top-story div:hover {
  background-color: white;
  color: var(--blue2);
  transition: 0.5s;
  cursor: pointer;
}
.full-h-loader-d {
  display: flex;
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;
}
.create-display-icon {
  background-color: var(--blue2);
  color: rgb(255, 255, 255);
  margin: 0px 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-display-icon:hover {
  background-color: white;
  color: var(--blue2);
  transition: 0.5s;
  cursor: pointer;
}
.create-display-icon:hover svg {
  transform: scale(1.1);
}

.create-display-icon svg {
  font-size: 20px;
}

.icons-top-story div:hover svg {
  transform: scale(1.1);
}

.icons-top-story svg {
  font-size: 20px;
}

.collapse.navbar-collapse {
  margin: 0 auto;
}
.header-container .d-flex button {
  margin: 0px 10px;
}
.header-container {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.navbar-nav.me-auto.my-2 {
  margin: 0 auto;
}
.top-20-x {
  top: 20px;
}
* {
  padding: 0;
  margin: 0;
}

.checkmarkPrice {
  display: flex;
  align-items: center;
  margin-right: 8px; /* Adjust the margin for spacing */
  font-size: 12px; /* Adjust the font size for the checkmark */
}

.checkmarkPrice::before {
  content: "✓";
  color: lime; /* Change this color to the desired color for the checkmark */
  font-size: 20px;
  margin-right: 8px;
}

.checkmark {
  color: black; /* Change this color to the desired color for the text */
}

.PriceX {
  display: flex;
  align-items: center;
  margin-right: 8px; /* Adjust the margin for spacing */
  font-size: 12px; /* Adjust the font size for the X */
}

.PriceX::before {
  content: "✕"; /* Use "✕" for X */
  color: red; /* Change this color to the desired color for the X */
  font-size: 20px; /* Adjust the font size for the X */
  margin-right: 8px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: lime;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 6;
  stroke: black;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px yellow;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
.video-container > div {
  width: 100% !important;
  margin: 0 auto;
  max-width: 700px;
  height: auto !important;
  /* max-height: 300px ; */
  border-radius: 10px;
}
.video-container > video {
  border-radius: 10px !important;
}
.demo-status-con .video-container > div {
  max-width: 100%;
}
.rounded-video-container {
  overflow: hidden; /* This ensures the video is contained within the rounded borders */
  max-width: 90%; /* Set maximum width to 100% to fit the screen width */
}

.profile-name {
  text-align: center;
  /* margin-bottom: 40px; */
}
.profile-page .video-container {
  margin-bottom: 20px;
}
.gen-video-c .ant-typography {
  margin-bottom: 0px !important;
}
.geneated-main-create .close-as-bn {
  display: flex;
  justify-content: flex-end;
}
.geneated-main-create .close-as-bn svg:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: 0.4s;
}
.checkbox-n {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox__label {
  display: inline-block;
  padding-left: 30px;
  margin-bottom: 10px;
  position: relative;
  font-size: 13px;
  color: black;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.checkbox__custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(145deg, #e81cff, #40c9ff) border-box;
  border: 2px solid transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.checkbox__input:checked + .checkbox__label .checkbox__custom {
  background-image: linear-gradient(145deg, #e81cff, #40c9ff);
  transform: rotate(45deg) scale(0.8);
}

.checkbox__label:hover .checkbox__custom {
  transform: scale(1.2);
}
.checbox-area-bcc {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.login-page {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
}
.sign-in-right-content {
  width: 600px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 100%;
  padding: 20px 0px;
  color: white;
}
.sign-in-form-input {
  height: 56px;
  width: 100%;
  border: 1px solid #000;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 30px;
  font-size: 18px;
  border-radius: 10px;
  background-color: #000000;

  color: white;
}
.logo-login-c {
  display: flex;
  justify-content: center;
}
.sign-in-form-input:focus {
  border: 1px solid #000 !important;
}
.sign-in-form-input::placeholder {
  color: rgb(118, 118, 118);
}
.unique-popup {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: black;
  padding: 10px;
  width: 530px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 18px 50px 5px;
  transition: all 0.5s ;
  z-index: 999; /* Ensure the popup is above other content */
}
.bigger-popup-c.unique-popup {

  bottom: auto;
  top: 10%;
  left: 10%;
  background-color: black;
  padding: 10px;
  width: 80%;
  max-height: auto;
}
.close-iconscs{
  display: flex;
  justify-content: flex-end;
  color: white;
}
.close-iconscs svg{
  position: absolute;
  top: 2;
  right: 2;
  z-index: 10;
  color: white;
}
.close-iconscs svg:hover{
  color: red;
  cursor: pointer;
}
.open-defailt-cb.unique-popup {
  width: 40px;
  display: flex;
  background-color: rgb(75, 75, 75);
}
.open-defailt-cb.unique-popup:hover {
  cursor: pointer;
  background-color: black;
}

.sign-in-form-title {
  /* color: #000; */
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 58px;
  font-weight: 600;
}
.loading-heigh-div {
  height: 700px;
  background-color: #000000;
}
.sign-in-form-text-block {
  /* color: #000; */
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}
.sign-in-form-text-link {
  /* color: #000; */
  font-size: 19px;
  font-weight: 700;
  text-decoration: none;
}
.sign-in-form-tag {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 30px;
  display: flex;
}
.sign-in-form-field-label {
  /* color: black; */
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 400;
  margin-top: 10px;
}
.sign-in-form-tag-border {
  width: 34%;
  height: 1px;
  background-color: #000;
}
.fonr-lgn-c {
  /* border: 1px solid rgb(0, 0, 0); */
  padding: 30px;
  /* margin-top: 30px; */
  border-radius: 10px;
}
.sign-in-form-tag-text {
  font-size: 19px;
}
.sign-in-submit-button {
  /* width: 100%; */
  height: 40px;
  box-shadow: 3px 4px 0 0 black;
  color: white;
  background-image: linear-gradient(to right, black, black);
  border-radius: 0;
  padding: 0px 10px;
  font-weight: 600;
  margin-top: 20px;
  border-color: transparent;
  border-radius: 5px;
}
.sign-in-submit-button.adjust-color--l{
  background-image: linear-gradient(to right, gold, yellow);
  box-shadow: 3px 4px 0 0 black;
  color: black;

}
.left-login-logo {
  display: flex;
  width: 100%;
}

.err-txt-signup {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
  color: brown;
}
.login-forget-btn {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.error-field {
  background-color: rgb(255, 207, 207);
}

.tabs-main-bx {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  min-height: 80vh;
  background-color: var(--bgBlack2);
}
.tabs-main-bx.tabs-scrol-y {
  max-height: 80vh;
  overflow-y: auto;
}
.card-imagine-create {
  overflow-y: auto;
}
.card-imagine-create .create-tab {
  background-color: transparent;
  margin-top: 0px;
}
.card-imagine-create2.zero-height-t .create-tab {
  height: auto !important;
}
.drop-hor-cb {
  width: 700px;
  min-height: 100px;
  overflow-x: auto;
}
.question-circle-c {
  color: black !important;
  stroke-width: 1px !important;
  /* border: 1px solid black; */
  border-radius: 50%;
  padding: 1px 4px;
  font-size: 15px !important;
  cursor: help;
}

.pagination-white .ant-pagination,
.pagination-white .ant-pagination svg,
.pagination-white .ant-pagination .ant-pagination-item-ellipsis {
  color: yellow !important;
}
:where(.css-dev-only-do-not-override-42nv3w).ant-pagination .ant-pagination-item-active{
  border-color: transparent !important;

}
.pagination-white .ant-pagination .ant-pagination-item-active a {
  color: black !important;
  background: linear-gradient(
    to right,
    orange,
    yellow
  )  ;
  border-color: transparent !important;
  border-radius: 5px;
}
.table-2-p {
  margin: 20px 0px;
}
.settings-p-bn {
  margin-top: 20px;
  color: white;
}
.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15),
    0 6px 12px 0 rgba(24, 94, 224, 0.15);
  padding: 0.5rem;
  border-radius: 99px;
  width: auto;
}
.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 100px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}
.glider {
  position: absolute;
  display: flex;
  height: 32px;
  width: 100px;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-left: 0.75rem;
  border-radius: 50%;
  background-color: #e6eef9;
  transition: 0.15s ease-in;
}

input[type="radio"]:checked + label {
  color: #185ee0;
}
input[type="radio"]:checked + label > .notification {
  background-color: #185ee0;
  color: #fff;
}

input[id="radio-1"]:checked ~ .glider {
  transform: translateX(0);
}

input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%);
}

input[id="radio-3"]:checked ~ .glider {
  transform: translateX(200%);
}

input[id="radio-4"]:checked ~ .glider {
  transform: translateX(300%);
}
.imagive-modal-cadrd {
  display: flex;
  justify-content: center;
}
.imagive-modal-cadrd .card21 {
  max-width: 550px;
  width: 100%;
  margin-left: 0px;
}
.magic-icon-h {
  margin-right: 10px;
  cursor: pointer;
}
.magic-icon-h:hover {
  transform: scale(1.1);
  transition: 0.2s;
}
@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }
}
.profile-edit-page .form-control {
  margin-top: 20px;
  color: black;
}
.balance-bx {
  color: white !important;
  margin-top: 20px;
}
.profile-edit-page {
  width: 300px;
  padding-top: 20px;
}
.create-tab {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  height: 100%;
  max-height: 550px;
}
.create-tab .ant-select-selector {
  border-radius: 20px !important;
}
.create-tab textarea {
  border-radius: 20px !important;
}

.duration-box-m {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}
.duration-box-m .ant-input-number {
  width: 200px;
  width: 100%;
}
.edit-video-btn {
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.quick-generates-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}
.quick-generates-div h6 {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}
.quick-generates-div svg {
  font-size: 25px;
  /* animation: glowingThunder 5s linear infinite; */
}
@keyframes glowingThunder {
  0% {
    color: yellow;
  }
  20% {
    color: #002bff;
  }
  40% {
    color: yellow;
  }
  60% {
    color: #002bff;
  }

  80% {
    color: yellow;
  }
}
.glow-on-hover {
  padding: 5px 15px;
  color: white;

  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 20px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 20px;
}

.glow-on-hover:hover {
  color: white;
}
.glow-on-hover:focus {
  color: white;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 20px;
  background-color: black;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.create-button-con {
  display: flex;
  justify-content: flex-end;
}
.create-tab-main {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.create-tab-main-row {
  width: 100%;
}
.create-tab-main-row .video-container {
  margin: 50px 0px 0px 30px;
}
.create-tab-main-row .video-container > div > video {
  border-radius: 15px !important;
}
.info-alert-bx {
  border-color: rgba(0, 0, 0, 0.2);
  margin-top: 40px;
}
.ring {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ring::before {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: ring 2s linear infinite;
}
.ring h1 {
  font-size: 9px;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 0px 5px 5px #ff2121;
  }
  25% {
    transform: rotate(90deg);
    box-shadow: 0px 5px 5px #fffb21;
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 0px 5px 5px #21c0ff;
  }
  75% {
    transform: rotate(270deg);
    box-shadow: 0px 5px 5px #bc21ff;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 0px 5px 5px #ff2121;
  }
}
.spinner {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.spinner::before,
.spinner:after {
  content: "";
  position: absolute;
  border-radius: inherit;
  background-color: black !important;
}

.spinner:before {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #ff00cc 0%, #333399 100%);
  animation: spin8932 0.5s infinite linear;
}

.spinner:after {
  width: 85%;
  height: 85%;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin8932 {
  to {
    transform: rotate(360deg);
  }
}
.spiner-main-box {
  display: flex;
}
.spiner-main-box svg {
  margin-top: 9px;
  margin-left: -29px;
  z-index: 15;
  margin-right: 20px;
}
.project-name {
  background-color: white;
  margin-bottom: 0px;
  padding: 5px 10px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project-name svg {
  margin-right: 5px;
}
.project-input-con {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}
.project-input-con:hover .project-name svg {
  margin-right: 0px;
  transition: 0.5s;
  transform: scale(1.1);
}
.story-box-main-c {
  width: 100%;
  max-width: 600px;
}
.video-card-story {
  background-color: black;
  color: white;
  border: 1px solid rgb(60, 60, 60);
  padding: 10px;
  border-radius: 5px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.video-card-story.active-vd-story {
  background-color: rgb(56, 56, 56);
  transition: all ease 0.4s;
}
.card-imagine-create2 .video-card-story {
  border-width: 0px;
}
.duration-box-vid {
  color: white;
  height: 0px;
  display: flex;
  justify-content: flex-end;
}
.duration-box-vid span {
  position: relative;
  margin-top: -30px;
  margin-right: 10px;
}
.card-imagine-create2 {
  background-color: transparent;
  display: flex;
  align-items: center;
}
.card-imagine-create2 .create-tab {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 0px;
}
.card-imagine-create2 .duration-box-m {
  margin-top: 0px;
}
.story-r-button {
  --hover-shadows: 8px 8px 16px black, -6px -6px 16px black;
  --accent: red;

  letter-spacing: 0.1em;
  border: 1px solid red;
  border-radius: 1.1em;
  background-color: #212121;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  transition: box-shadow ease-in-out 0.3s, background-color ease-in-out 0.1s,
    letter-spacing ease-in-out 0.1s, transform ease-in-out 0.1s;
}

.story-r-button:hover {
  box-shadow: var(--hover-shadows);
}

.story-r-button:active {
  box-shadow: var(--hover-shadows), var(--accent) 0px 0px 30px 5px;
  background-color: var(--accent);
  transform: scale(0.95);
}

.story-a-button {
  --hover-shadows: 8px 8px 16px black, -6px -6px 16px black;
  --accent: green;

  letter-spacing: 0.1em;
  border: 1px solid green;
  border-radius: 1.1em;
  background-color: #212121;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  transition: box-shadow ease-in-out 0.3s, background-color ease-in-out 0.1s,
    letter-spacing ease-in-out 0.1s, transform ease-in-out 0.1s;
}

.story-a-button:hover {
  box-shadow: var(--hover-shadows);
}

.story-a-button:active {
  box-shadow: var(--hover-shadows), var(--accent) 0px 0px 30px 5px;
  background-color: var(--accent);
  transform: scale(0.95);
}

.ap-rej-btns {
  display: flex;
  justify-content: space-between;
}
.generated-Videos-left {
  color: white;
  margin-bottom: 10px;
}
h6.generated-Videos-left {
  width: 100%;
}
.video-container-round > div > video {
  border-radius: 5px;
  margin-bottom: -5px;
}
.card-box-col-ctory {
  padding: 5px;
}
.sceen-position {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.upload-iocn-story {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-iocn-story:hover {
  cursor: pointer;
}
.upload-iocn-story:hover svg {
  transform: scale(1.02);
  transition: 0.4s;
}
.upload-iocn-story svg {
  font-size: 100px;
  color: grey;
}
.upload-icon-story {
  display: flex;
  justify-content: flex-end;
}
.upload-icon-story b {
  background-color: white;

  border-radius: 20px;
  width: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
}
.create-pro-for {
  margin-top: 20px;
  margin-bottom: 20px;
}
.create-btn-pro {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
.save-btnx {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.story-project-main {
  display: flex;
  margin-top: 15px;
}

.generated-row-story {
  width: 0px !important;
  min-width: 0px !important;

  border-radius: 10px;
  margin-top: 0px;
  min-height: 80vh;
  transition: all ease 0.5s;
  background-color: var(--bgBlack2);
  overflow-x: clip;
}
.ant-input,
.ant-picker-large {
  border-color: black;
  border-radius: 0px;
}
.media-type-c {
  display: flex;
  /* justify-content: space-around; */
  margin-top: 10px;
}
.media-type-c .hover-card-b {
  margin: 0px 5px;
}
.scene-name-in .ant-input {
  margin-top: 10px;
  background-color: var(--bgBlack2);
  color: white;
  border-color: transparent;
}
.scene-name-in .ant-input::placeholder {
  color: rgb(139, 139, 139);
}
.card-box-col-ctory .ant-input-number {
  background-color: black;
  border-color: transparent;
}
.card-box-col-ctory .ant-input-number:hover {
  border-color: rgb(181, 181, 181);
}
.card-bx-hor {
  background-color: var(--bgBlack2);
  display: flex;
  width: 100%;
  margin-left: 10px;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.card-bx-ver {
  background-color: var(--bgBlack2);
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow-y: auto;
  border-radius: 10px;
  padding: 10px;
  min-width: 300px !important;
}
.drop-hor-cb {
  display: flex;
}

.card-box-col-ctory .ant-input-number-input {
  color: white !important;
  border-color: transparent;
}
.ant-input-number-input::placeholder {
  color: rgb(137, 137, 137) !important;
}
.generated-row-story.show-left-s {
  min-width: 300px !important;
  padding: 20px;
  margin-right: 10px;
}
.generated-Videos-left {
  display: flex;
  justify-content: space-between;
}
.black-input-con .ant-select-selector,
.black-input-con .ant-select-selection-search {
  background-color: black !important;
  color: white;
}
.generated-Videos-left svg:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s;
}
.video-icon-d {
  color: white;
  margin-right: 20px;
  margin-top: -10px;
  cursor: pointer;
}
.video-icon-d:hover {
  color: #7a00ff;
  transition: 0.4s;
}
.loading-full-page2 .spiner-main-box svg {
  margin-top: 12px;
  margin-left: -28px;
  z-index: 15;
  margin-right: 20px;
}
.loading-full-page .spiner-main-box svg {
  margin-top: 12px;
  margin-left: -28px;
  z-index: 15;
  margin-right: 20px;
}
.table-jobs {
  width: 800px;
  margin: 20px;
  background-color: white;
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.ant-btn-primary.ant-btn:hover {
  background-color: var(--blue1);
}
.black-button,
.ant-btn-primary {
  background-color: var(--blue1);
  border-color: var(--blue1);
  color: black;
}

.tabs-buttons {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.tabs-buttons button {
  margin: 10px;
}
.ant-btn-primary:hover {
  background-color: var(--blue1) !important;
  border-color: var(--blue1) !important;
  transition: 0.4s;
  color: black !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 12px 10px -10px;
}
.black-button,
.ant-btn-primary:focus {
  background-color: black;
  border-color: black;
  color: white;
}
.no-buttons-modal .ant-modal-footer {
  display: none;
}
.video-container.radius-video > div > video {
  border-radius: 10px;
}
.no-buttons-modal .ant-modal-close-x .anticon svg {
  top: -12px;
  position: relative;
  right: -12px;
}
.video-main-edit-p {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.jobs-video-main {
  display: flex;
  justify-content: center;
}

.display-bx-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.display-bx-main p {
  margin-right: 20px;
}
.momen-created-at {
  display: flex;
  margin: 10px 0px;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
}
.video-main-bg-box {
  width: 100vw;
  height: 100vh;
}
.video-main-bg-box > div {
  width: 100vw !important;
  height: 100vh !important;
  object-fit: cover;
  padding-top: 56px;
}
.video-main-bg-box > div > video {
  object-fit: cover;
}
.home-page-relitive {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}
.box-home-m1 {
  margin-top: 250px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
}
.img-bx-imgsc {
  padding: 10px;
}
.img-bx-imgsc img {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  object-fit: cover;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.content-tab {
  width: 100%;
  max-width: 800px;
}
.vd-nm-pr > div {
  width: 100% !important;
  height: auto !important;
  max-height: 300px;
}
.react-player__download {
  display: none; /* This will hide the download button */
}
.vd-nm-pr > div > video {
  border-radius: 10px !important;
  height: auto !important;
}
.icons-bx-ri-vd {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-btn:hover {
  position: relative;
  transform: scale(1.02);
  transition: 0.5s;
}
.icons-bx-ri-vd button {
  width: 30px;
}
.icons-bx-ri-vd button svg {
  margin-left: -5px;
}
.feed-view-area {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
}
.feed-profile-area > img {
  height: 20px;

  margin-right: 10px;
}
.feed-profile-area > img {
  font-size: 14px;
}
.feed-profile-area {
  display: flex;
  margin-bottom: 10px;
}

.feed-profile-area p {
  margin-bottom: 0px;
}
.feed-view-area .video-container > div > video {
  border-radius: 10px !important;
}
.feed-video-mc1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.likes-area {
  display: flex;
  padding: 5px 10px;
}
.likes-area > span {
  margin-left: 15px;
}
.likes-area .heart-icon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.likes-area > span b {
  font-size: 12px;
  margin-left: 5px;
}
.feed-video-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  margin-top: -10px;
  background-color: #002bff;
  z-index: 12;
}
.feed-video-footer p {
  margin-bottom: 0px;
}
.likes-area svg:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: 0.5s;
}
.fixed-header {
  position: fixed;
  width: 100%;
  z-index: 100;
}
.feed-view-area .video-feed-mbl-w {
  display: flex;
  flex-direction: column;
  background-color: var(--bgBlack2);
  justify-content: space-between;
  padding: 15px;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
}
.feed-page {
  padding-top: 80px;
}

.heart-container {
  --heart-color: rgb(255, 91, 239);
  position: relative;
  width: 20px;
  height: 20px;
  transition: 0.3s;
}

.heart-container .checkbox {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.heart-container .svg-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart-container .svg-outline,
.heart-container .svg-filled {
  fill: var(--heart-color);
  position: absolute;
}

.heart-container .svg-filled {
  animation: keyframes-svg-filled 1s;
  display: none;
}

.heart-container .svg-celebrate {
  position: absolute;
  animation: keyframes-svg-celebrate 0.5s;
  animation-fill-mode: forwards;
  display: none;
  stroke: var(--heart-color);
  fill: var(--heart-color);
  stroke-width: 2px;
}

.heart-container .checkbox:checked ~ .svg-container .svg-filled {
  display: block;
}

.heart-container .checkbox:checked ~ .svg-container .svg-celebrate {
  display: block;
}

@keyframes keyframes-svg-filled {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
    filter: brightness(1.5);
  }
}

@keyframes keyframes-svg-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    filter: brightness(1.5);
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
    display: none;
  }
}

.InputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #004dc6, #06b4ff);

  border-radius: 30px;
  padding: 6px;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
}
.navbar.navbar-expand-lg.navbar-light {
  background-color: transparent;
}

.nav-link {
  color: white;
  font-size: 12px;
}
.nav-link.active {
  color: white !important;
  font-weight: 600;
}
.nav-link:hover {
  color: white;
}

.button-21 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px; /* Adjust the padding to reduce the size */
  border: 0;
  position: relative;
  overflow: hidden;
  border-radius: 10rem;
  transition: all 0.02s;
  font-weight: bold;
  color: black;
  z-index: 0;
  box-shadow: 0 0px 7px -5px rgba(0, 0, 0, 0.5);
}

.button-21:hover {
  background: rgb(193, 228, 248);
  color: rgb(33, 0, 85);
}

.button-21:active {
  transform: scale(0.97);
}

.hoverEffect {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.hoverEffect div {
  background: rgb(222, 0, 75);
  background: linear-gradient(
    90deg,
    rgb(220, 0, 74) 0%,
    rgb(178, 36, 253) 49%,
    #42caff 100%
  );
  border-radius: 40rem;
  width: 10rem;
  height: 10rem;
  font-weight: 600;
  transition: 0.4s;
  /* filter: blur(1px); */
  animation: effect infinite 3s linear;
  opacity: 0.5;
}
.button-21:hover .hoverEffect div {
  width: 8rem;
  height: 8rem;
}
.editor-container .button-21 {
  color: black;
}
.editor-container .hoverEffect div {
  background: #c6c6c6 !important;
  /* opacity: 1; */
  /* color: white; */
  border-radius: 0px;
  /* opacity: 1; */
  /* color: white; */
}
.card-imagine-create2 .button-21 {
  transform: scale(0.8);
  margin-top: -4px;
}

.custom-button {
  background: linear-gradient(
    to right,
    rgb(33, 0, 85) 100%,
    rgb(193, 228, 248) 0%
  );
  border: none;
  color: white;
  padding: 6px 20px; /* Adjust the padding to reduce the height */
  border-radius: 5px;
  cursor: pointer;
}

/* Add hover effect */
.custom-button:hover {
  background: linear-gradient(to right, rgb(23, 0, 75), rgb(183, 218, 238));
}

@keyframes effect {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.checkbox-wrapper * {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.checkbox-wrapper input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper label {
  --size: 25px;
  --shadow: calc(var(--size) * 0.07) calc(var(--size) * 0.1);
  position: relative;
  display: block;
  width: var(--size);
  height: var(--size);
  margin: 0 auto;
  background-color: #4158d0;
  background: linear-gradient(to bottom, #42caff 0%, #e81aff 100%);
  border-radius: 50%;
  box-shadow: 0 var(--shadow) #ffbeb8;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color,
    0.2s ease box-shadow;
  overflow: hidden;
  z-index: 1;
}

.checkbox-wrapper label:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: calc(var(--size) * 0.7);
  height: calc(var(--size) * 0.7);
  margin: 0 auto;
  background-color: #fff;
  transform: translateY(-50%);
  border-radius: 50%;
  box-shadow: inset 0 var(--shadow) #ffbeb8;
  transition: 0.2s ease width, 0.2s ease height;
}

.checkbox-wrapper label:hover:before {
  width: calc(var(--size) * 0.55);
  height: calc(var(--size) * 0.55);
  box-shadow: inset 0 var(--shadow) #ff9d96;
}

.checkbox-wrapper label:active {
  transform: scale(0.9);
}

.checkbox-wrapper .tick_mark {
  position: absolute;
  top: -1px;
  right: 0;
  left: calc(var(--size) * -0.05);
  width: calc(var(--size) * 0.6);
  height: calc(var(--size) * 0.6);
  margin: 0 auto;
  margin-left: calc(var(--size) * 0.14);
  transform: rotateZ(-40deg);
}

.checkbox-wrapper .tick_mark:before,
.checkbox-wrapper .tick_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 2px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

.checkbox-wrapper .tick_mark:before {
  left: 0;
  bottom: 0;
  width: calc(var(--size) * 0.1);
  height: calc(var(--size) * 0.3);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.23);
  transform: translateY(calc(var(--size) * -0.68));
}

.checkbox-wrapper .tick_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(var(--size) * 0.1);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.23);
  transform: translateX(calc(var(--size) * 0.78));
}

.checkbox-wrapper input[type="checkbox"]:checked + label {
  background-color: #4158d0;
  background: linear-gradient(to bottom, #42caff 0%, #e81aff 100%);
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 4px 3px; */
}

.checkbox-wrapper input[type="checkbox"]:checked + label:before {
  width: 0;
  height: 0;
}

.checkbox-wrapper input[type="checkbox"]:checked + label .tick_mark:before,
.checkbox-wrapper input[type="checkbox"]:checked + label .tick_mark:after {
  transform: translate(0);
  opacity: 1;
}

.voice-main-c {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.voice-main-c i {
  margin-left: 5px;
  font-size: 13px;
}

/*Magic card21*/
.card21 {
  width: 80%;
  margin-left: 10%;
  height: auto;
  /* background: #f5f5f5; */
  overflow: visible;
  /* box-shadow: 0 5px 20px 2px rgba(0,0,0,0.1); */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  border-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
}
.editor-container .card21 {
  background-image: linear-gradient(to right, #444444, #545454);

  margin-top: 20px;
}
.button-area-card {
  margin-top: -30px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.card21-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  padding: 0 1rem;
}

.card21-img {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  transform: translateY(-50%);
  background: #42caff;
  background: linear-gradient(to bottom, #42caff 0%, #e81aff 100%);
  position: relative;
  transition: all 0.3s ease-in-out;
}
.editor-container .card21-img {
  background: black;
  background: linear-gradient(to bottom, black 0%, #001240 100%);
}

.card21-img::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  border: 0.5rem solid white;
}

/*text21*/
.text21-body {
  text-transform: uppercase;
  font-size: 0.75em;
  color: #42caff;
  letter-spacing: 0.05rem;
}

.text21-body {
  text-align: center;
  font-size: 16px;
  margin-bottom: 0px;
}
.txt-card-21 {
  /* text-align: center; */
  margin-top: -20px;
  margin-bottom: 20px;
  color: rgb(85, 85, 85);
  display: flex;
  flex-direction: column;
}
.editor-container .txt-card-21,
.editor-container .text21-body,
.editor-container .text21-body,
.editor-container .checkbox__label {
  color: white;
}
.editor-container .create-tab-main-row {
  background-color: #a3a3a4;
  height: 100vh;
  padding: 20px;
}
.txt-card-21 span {
  text-align: center;
  margin-bottom: 20px;
}

.quer-genetated p {
  background-color: white;
  width: 100%;
  margin-bottom: 0px;
  padding: 10px;
  border-radius: 20px;
}
.editor-container .quer-genetated p {
  background-color: #ffffff14;
}
.editor-container .quer-genetated *,
.geneated-main-create .close-as-bn {
  color: white;
}
.geneated-main-create {
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  max-height: 550px;
}
.gen-video-c {
  overflow-y: scroll;
  height: 100%;
}
.geneated-main-create .quer-genetated {
  margin-bottom: 10px;
}
.geneated-main-create .quer-genetated p {
  display: flex;
  justify-content: space-between;
}
.geneated-main-create .quer-genetated:hover p {
  background-color: rgba(255, 255, 255, 0.45);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.geneated-main-create .quer-genetated.act-selected-v p {
  background-color: rgba(255, 255, 255, 0.2);
}
.create-tab-main-row .geneated-main-create .video-container {
  margin: 0px 0px 10px 0px;
  width: 70%;
  margin-left: 15%;
}
.video-main-edit {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.video-thumbnail-img {
  width: 576px;
  height: 320px;
}
.ant-modal-content .video-thumbnail-img {
  width: 100%;
}
.video-thumbnail-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.load-more-c {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.play-icon-vd {
  position: absolute;
  top: 100px;
  /* left: 0;
  bottom: 0;
  right: 0; */
  z-index: 20;
  background-color: #002bff;
}

.large-play-btn {
  width: 60px !important;
  height: 60px !important;
  font-size: 25px !important;
  border-radius: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.large-play-btn svg {
  margin-left: 4px !important;
}
.icons-bx-ri-vd2 {
  display: flex;
  justify-content: center;
}
.large-play-btn {
  margin-top: -180px;
  background: rgba(240, 248, 255, 0.343);
  color: #103dce;
}
.large-play-btn:hover {
  color: #103dce;
}
.small-play-btn {
  width: 45px !important;
  height: 45px !important;
  border-radius: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-play-btn svg {
  font-size: 20px !important;
  margin-left: 4px !important;
  position: absolute;
}
.play-video-loader {
  margin-top: -90px;
  font-size: 30px;
}
.play-video-loader svg {
  color: black;
}
.small-play-btn {
  margin-top: -90px;
  background: linear-gradient(
    90deg,
    rgba(220, 0, 74, 0.5) 0%,
    rgba(178, 36, 253, 0.5) 49%,
    #43caff 100%
  );
}
.arow-div-con {
  display: flex;
  justify-content: space-between;
  height: 0px;
  opacity: 0;
}
.video-card-story:hover .arow-div-con {
  opacity: 1;
  transition: 0.4s;
}
.arow-div-con svg {
  position: relative;
  z-index: 15;
  margin: 5px;
  background-color: black;
  padding: 2px;
  border-radius: 5px;
}
.arow-div-con svg:hover {
  transform: scale(1.2);
  transition: 0.4s;
  cursor: pointer;
}
.arow-div-con svg:active {
  color: #4158d0;
  background-color: white;
}
.search-for-craate-story {
  display: flex;
  justify-content: center;
  height: 0px;
}
.searct-create-m {
  position: absolute;
  top: 15px;
}
.searct-create-m .ant-select-arrow {
  color: white !important;
}
.searct-create-m .ant-select-selection-search {
  padding-right: 30px;
}
.searct-create-m .ant-select-selector {
  padding-left: var(--size-button);
  height: var(--size-button);
  font-size: 15px;
  border: none;
  color: #fff;
  outline: none;
  width: var(--size-button);
  color: white;
  transition: all ease 0.3s;
  background-color: #191a1e !important;
  border-color: transparent !important;
  box-shadow: 0px 0px 0px #0e0e0e, 0px 0px 0px rgb(95 94 94 / 25%),
    inset 1.5px 1.5px 3px #0e0e0e, inset -1.5px -1.5px 3px #5f5e5e;
  border-radius: 50px;
  padding: 5px;
}
.searct-create-m .ant-select-selection-placeholder {
  color: rgb(119, 119, 119) !important ;
}
.searct-create-m .create-button-con {
  color: white;
  margin-left: -35px;
  position: relative;
  z-index: 20;
  margin-right: 10px;
}
.searct-create-m .create-button-con svg:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s;
}
.searct-create-m .create-button-con svg:active {
  color: #4158d0;
}

.search-for-craate-story .create-tab {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 0px;
}
.search-for-craate-story .duration-box-m {
  margin-top: 0px;
}

.play-feed-profile .large-play-btn {
  width: 50px !important;
  height: 50px !important;

  margin-top: -90px;
}
.feed-video-footer.thumbnail-footer {
  background-color: black;
  color: white;
  border-radius: 20px;
  margin-bottom: -40px;
  z-index: 15;
  margin-top: 5px;
  width: 94%;
  margin-left: 3%;
}
.feed-video-footer.thumbnail-footer b {
  font-weight: 500;
}
.duration-box b {
  background-color: black;
  padding: 7px 10px;
  border-radius: 20px;
  margin: 0px 10px;
  margin-top: -40px;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: white;
}
.feed-video-footer .ant-typography {
  color: white;
  margin-bottom: 0px;
  margin-left: 5px;
}
.ant-layout .ant-layout-sider {
  background-color: white;
}
.reimagine-b-mai {
  display: flex;
  justify-content: flex-end;
}
.duration-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0px;
}
.logo-img-video-create {
  width: 10px;
  margin-right: 7px;
  margin-left: 10px;
  background-color: white;
  border-radius: 50%;
  padding: 1px;
  transform: scale(2);
}
.logo-img-bc {
  /* margin: 5px 10px 5px 10px !important; */
  width: 45px;
}
.live-tag-cu {
  position: absolute;
  top: 10px;
  right: 0px;
}
.navbar-brand-logo {
  display: flex;
  justify-content: center;
  width: 100%;
}
.ant-menu-item .logo-img-bc {
  height: 80%;
  width: auto;
  margin: 0px;
}
.ant-menu-title-content {
  font-size: 12px;
}
.ant-menu-item {
  border-radius: 0px !important;
}
.ant-menu .ant-menu-item-selected {
  background-color: #c1ff98;
}
.ant-menu .ant-menu-item-selected .ant-menu-title-content {
  color: black;
}
.optional-text {
  font-size: 12px;
  margin-left: 10px;
}
.business-tools-btn {
  width: 100%;
  display: flex;
  height: 0px;
  justify-content: flex-end;
}
.business-tools-btn button {
  position: relative;
  top: -40px;
  right: -20px;
  padding-left: 10px;
  padding-right: 10px;
}
.business-tools-btn2 {
  width: 100%;
  display: flex;
  height: 0px;
}
.business-tools-btn2 button {
  position: relative;
  top: -40px;
  left: -20px;
  padding-left: 10px;
  padding-right: 10px;
}
.modal-upload-c {
  margin-top: 20px;
}
.sel-asset-c {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.card-bx-con-fl2 .img-gen {
  max-height: 150px;
  width: auto;
  object-fit: contain;
  border-radius: 5px;
}
.card-bx-con-fl {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
}
.card-bx-con-fl2 {
  margin: 5px;
  padding: 5px;

  display: flex;
  justify-content: center;
}
.card-bx-con-fl2 p {
  margin-bottom: 0px;
}
.tags-ctype-btns {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.login-box-bg {
  background-color: var(--bgBlack2);
  border-radius: 15px;
  height: 100%;
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.black-forget-modal .ant-modal-body {
  background-color: var(--bgBlack2);
}
.login-btn-c21 {
  display: flex;
  justify-content: flex-end;
}
.black-modal .ant-modal-content {
  box-shadow: 15px 15px 30px rgb(25, 25, 25), -15px -15px 30px rgb(60, 60, 60);
  background-color: black;
  color: white;
}
.black-modal .ant-modal-close {
  color: white;
}
.login-box-bg h3,
.login-box-bg p {
  color: white;
}
.signup-txt-login {
  background: linear-gradient(#ffc7cb 0%, rgb(246, 246, 246) 100%);
  /* background: -webkit-linear-gradient(#eee, #333); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none;
}
.signup-txt-login:hover {
  background: linear-gradient(white 0%, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  transform: scale(1.01);
}

.btn34 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  overflow: hidden;
  height: 2.5rem;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  margin-top: 20px;
  animation: gradient_301 5s ease infinite;
  border: double 3px transparent;
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      #ffdb3b 10%,
      #fe53bb 45%,
      #8f51ea 67%,
      #0044ff 87%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}

#container-stars34 {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

.btn34 strong {
  z-index: 2;
  font-size: 12px;
  letter-spacing: 5px;
  color: #ffffff;
  text-shadow: 0 0 4px white;
}

#glow34 {
  position: absolute;
  display: flex;
  width: 12rem;
}

.circle34 {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.circle34:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.circle34:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

.btn34:hover #container-stars34 {
  z-index: 1;
  background-color: #212121;
}

.btn34:hover {
  transform: scale(1.01);
}

.btn34:active {
  border: double 4px #fe53bb;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.btn34:active .circle34 {
  background: #fe53bb;
}
.video-main-reimag {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
}
.video-main-reimag button {
  position: relative;
  top: -20px;
  right: 7px;
  z-index: 20;
}

#stars34 {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

#stars34::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

#stars34::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

#stars34::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

#stars34::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.login-butotn-d {
  display: flex;
  justify-content: flex-end;
}
.up-bx-mnbcs {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.pricing-modal .ant-modal-close-x {
  display: none;
}
.search-video-content {
  display: flex;
  justify-content: center;
}

.video-feed-mbl-w .ant-typography {
  max-width: 300px;
}
.card61 {
  width: 100%;
  height: auto;
  background-color: #4158d0;
  background-image: linear-gradient(
    45deg,
    #4158d0 0%,
    rgb(0, 17, 103) 46%,
    #4158d0 100%
  );
  border-radius: 8px;
  color: white;
  overflow: hidden;
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
}

.card-content61 {
  padding: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.card-content61 .card-title61 {
  font-size: 24px;
  font-weight: 700;
  color: inherit;
  text-transform: uppercase;
}

.card-content61 .card-para61 {
  color: inherit;
  opacity: 0.8;
  font-size: 14px;
}

.card61:hover {
  transform: rotateY(2deg) rotateX(2deg) scale(1.01);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.play-video-likes-b {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card61:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 1;
}

.card61:hover:before {
  transform: translateX(-100%);
}

.card61:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 1;
}

.card61:hover:after {
  transform: translateX(100%);
}

.prifing-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding-left: 10px;
}
.btnpr {
  width: 6.5em;
  height: 2.3em;
  margin: 0.5em;
  background: black;
  color: white;
  border: none;
  border-radius: 0.625em;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.prbutton:hover {
  color: black;
}

.prbutton:after {
  content: "";
  background: white;
  position: absolute;
  z-index: -1;
  left: -20%;
  right: -20%;
  top: 0;
  bottom: 0;
  transform: skewX(-45deg) scale(0, 1);
  transition: all 0.5s;
}

.prbutton:hover:after {
  transform: skewX(-45deg) scale(1, 1);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.price-card-box {
  padding: 10px;
}
.icons-bx-main-ne {
  background-color: var(--bgBlack2);
  padding: 20px;
  border-radius: 20px;
}
.img-prev-ibc {
  margin: 0 auto;
}
.click-profile-b:hover {
  cursor: pointer;
  transform: scale(1.02);
  transition: 0.4s;
}
.profile-feed-a {
  background-color: var(--bgBlack2);
  padding: 30px;
  border-radius: 10px;
  max-width: 900px;
  margin: 0 auto;
}
.profile-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  color: rgb(187, 187, 187);
  padding-bottom: 20px;
  border-bottom: 1px solid grey;
}
.profile-name img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
}
.play-feed-profile img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 24px;
  border-radius: 10px;
}
.profile-name.pro-tb img {
  width: 100px;
  height: auto;
  object-fit: contain;
  border-radius: 5px;
  margin-right: 20px;
}
.white-color-para {
  color: white;
}
.icons-bx-sd {
  display: flex;
  align-items: center;
}
.icons-bx-sd button {
  margin-right: 5px;
}
.profile-edit-page {
  display: flex;
  justify-content: center;
}
.profile-edit-page table td {
  padding: 10px 20px;
}
.profile-edit-page table {
  background-color: white;
  width: 550px;
  border-radius: 15px;
}
.or-bx-vv {
  display: flex;
  justify-content: center;
  margin: 5px 0px;
}
.avatar-c-bx {
  display: flex;
  margin-bottom: 0px;
}
.avatar-c-bx img {
  border-radius: 10px;
  width: 100px;
  margin-bottom: 10px;
}
.create-avatar-bx {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.play-feed-profile img:hover {
  cursor: pointer;
  transition: 0.4s;
  transform: scale(1.02);
}

.table-box-adwance td {
  padding: 5px 10px;
}
.table-box-adwance .InputContainer {
  margin-bottom: 5px;
}
.collapse-style-adwance .ant-collapse {
  width: 320px;
}
.collapse-style-adwance .ant-collapse-header {
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
  color: white;
}

.feed-vide-share-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.collapse-style-adwance {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}
.likes-fav-video {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.likes-fav-video b {
  color: white;
}
.white-color-paragraph .ant-typography {
  color: white;
}
.star-div {
  display: flex;
}
.total-video-profile {
  padding-left: 65px;
  margin-top: 10px;
}
.img-prev-ibc {
  width: 100px;
}
.pic-2vid0c {
  width: 10px;
  height: 0px;
  display: none;
}
.container212 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.InputContainer .ant-select-selector {
  border-radius: 20px;
}
.container212 {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.container212 svg {
  position: relative;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  transition: all 0.3s;
  fill: #666;
}
.color-select-boxz {
  display: flex;
  flex-wrap: wrap;
}
.color-select-boxz div {
  padding: 5px;
  margin: 5px;

  color: white;
  border-radius: 5px;
  cursor: pointer;
  min-width: 50px;
  text-align: center;
  height: 30px;
}
.color-select-boxz span:hover {
  transform: scale(1.1);
  transition: 0.4s;
}
.flex-end {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.container212 svg:hover {
  transform: scale(1.1);
}

.container212 input:checked ~ svg {
  fill: #ffeb49;
}
.play-feed-profile .video-container video {
  border-radius: 5px;
}
.play-feed-profile .video-container video {
  border-radius: 5px;
}

.container-s {
  position: relative;
  --size-button: 40px;
  color: white;
  margin-left: 100px;
}

.input-s {
  padding-left: var(--size-button);
  height: var(--size-button);
  font-size: 15px;
  border: none;
  color: #fff;
  outline: none;
  width: var(--size-button);
  transition: all ease 0.3s;
  background-color: #191a1e;
  box-shadow: 1.5px 1.5px 3px #0e0e0e, -1.5px -1.5px 3px rgb(95 94 94 / 25%),
    inset 0px 0px 0px #0e0e0e, inset 0px -0px 0px #5f5e5e;
  border-radius: 50px;
  cursor: pointer;
}

.input-s:focus,
.input-s:not(:invalid) {
  width: 200px;
  cursor: text;
  box-shadow: 0px 0px 0px #0e0e0e, 0px 0px 0px rgb(95 94 94 / 25%),
    inset 1.5px 1.5px 3px #0e0e0e, inset -1.5px -1.5px 3px #5f5e5e;
}

.input-s:focus + .icon-s,
.input-s:not(:invalid) + .icon-s {
  pointer-events: all;
  cursor: pointer;
}

.container-s .icon-s {
  position: absolute;
  width: var(--size-button);
  height: var(--size-button);
  top: 0;
  left: 0;
  padding: 8px;
  pointer-events: none;
}

.container-s .icon-s svg {
  width: 100%;
  height: 100%;
}
.navbar-my-main {
  display: flex;
  align-items: center;

  justify-content: space-between;
}
.container-fluid.my-container-flui {
  display: flex;
  flex-wrap: nowrap;
  color: white;
}
.header-right-c {
  display: flex;
  align-items: center;
}
.style-card-mn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin: 10px;
  border-radius: 5px;
}
.style-card-mn img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: white;
}
.style-card-mn p {
  margin-bottom: 0px;
}
.black-modal .ant-modal-header {
  background-color: black;
}
.black-modal .ant-modal-title {
  color: white;
}
.loading-full-page {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-full-page .spiner-main-box {
  transform: scale(3);
}
.loading-full-page.trasparent-loader {
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.5);
}
.loading-full-page2 {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-full-page2 .spiner-main-box {
  transform: scale(3);
}
.cta-buttons-c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cta {
  position: relative;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  text-align: left;
  margin-top: 15px;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}
.clicked-thunder-query {
  font-size: 12px;
  font-weight: 700;
  position: relative;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  text-align: left;
  margin-top: 15px;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
  border-radius: 20px;
}

.cta span {
  position: relative;
  font-size: 12px;
  font-weight: 700;
  color: black;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: black;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgb(227, 213, 255),
    rgb(255, 231, 231)
  );
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}
.cta-buttons-c h6 {
  margin-top: 10px;
  padding-top: 5px;
  width: 100%;
  margin-left: 10px;
}
.gradient-alert {
  background: linear-gradient(-45deg, rgb(227, 213, 255), rgb(255, 231, 231));
  background-size: 400% 400%;
  animation: gradient31 5s ease infinite;
  border-color: transparent;
}
.thunder-icon {
  font-size: 22px;
}
@keyframes gradient31 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.all-gen-video-txt {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.input-search-story.input-s {
  padding: 5px 10px;
  width: 100%;
  margin: 10px 0px;
}
.button-tab {
  padding: 0.6em 1.3em;
  font-weight: 900;
  font-size: 12px;
  background: linear-gradient(
    to right,
    rgba(231, 71, 132, 0.3),
    rgba(0, 170, 255, 0.3)
  );
  /* border-radius: 10px; */
  border: none;
  color: aliceblue;
  margin: 3px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  /* box-shadow: 0 0 10px rgba(55, 0, 255, 0.5); */
  cursor: pointer;
  border-radius: 5px;

  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.button-tab:hover {
  background: linear-gradient(
    to right,
    rgba(231, 71, 132, 0.7),
    rgba(0, 170, 255, 0.7)
  );
  transition: 0.4s ease all;
}
.gb-tag-brand {
  background: linear-gradient(
    to right,
    rgba(231, 71, 132, 0.3),
    rgba(0, 170, 255, 0.3)
  );
  color: white;
  font-weight: 500;
  border-color: transparent;
  cursor: pointer;
}
.gb-tag-brand.active-ctype-tag {
  background: linear-gradient(
    to right,
    rgba(231, 71, 132, 1),
    rgba(0, 170, 255, 1)
  );
  color: white !important;
  border-color: transparent;
}
.black-input {
  background-color: rgb(28, 28, 28);
  color: white;
}
.black-input::placeholder {
  color: #aaaaaa;
}
.ant-tour-close {
  display: none !important;
}
.ant-tour-step-move {
  transition: transform 0.7s ease; /* Adjust the duration (0.3s) and timing function (ease) as needed */
}
.button-tab.active-btn-t {
  background: linear-gradient(
    to right,
    rgba(231, 71, 132, 1),
    rgba(0, 170, 255, 1)
  );
  transform: scale(1.1);
  transition: 0.4s ease all;
}
.term-page-box-fc {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  color: black;
  max-height: 450px;
  overflow-y: scroll;
  margin-bottom: 20px;
}
.term-page-box-fc p {
  margin-left: 20px;
}
.ant-tour {
  width: 320px;
}
.img-table {
  width: 100px;
  height: auto;
  object-fit: contain;
  border-radius: 5px;
}
.table-box-character {
  margin-top: 10px;
  width: 100%;
  min-width: 700px;
}
.text-meda-desc {
  max-width: 300px;
  max-height: 100px;
  overflow-y: auto;
}
.delete-icon {
  font-size: 20px;
  cursor: pointer;
}
.delete-icon:hover {
  transform: scale(1.1);
  transition: 0.4s;
  color: red;
}
.delete-icon:active {
  color: rgb(158, 1, 1);
}
.hidden-textarea {
  border-color: transparent;
  border-radius: 5px;
  background-color: rgb(36, 36, 36);
  color: white;
}
.hidden-textarea:hover {
  border-color: #ddd;
  background-color: black;

}
.hidden-textarea.ant-input::placeholder {
color: #7a7a7a;


}
.table-top-filters {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.black-table tr {
  background-color: black;
  color: white;
}
.black-table tr:hover td {
  background-color: rgb(15, 15, 15) !important;
  transition: 0s !important;
}

.black-table tr:hover {
  background-color: rgba(0, 0, 0, 0.075) !important;
}
.black-table .ant-table-thead > tr > th,
.black-table .ant-table-thead > tr > td {
  border-color: orange;
}
.black-table .ant-table-tbody > tr > th,
.black-table .ant-table-tbody > tr > td {
  border-color: orange !important;
}
.black-table .ant-table-thead > tr > td,
.black-table th.ant-table-cell {
  background: linear-gradient(
    to right,
    orange,
    yellow
  ) !important ;
  color: black !important;
  border-bottom: black;
}
/* .black-table thead tr{
  background: linear-gradient(
    to right,
    orange,
    yellow
  ) !important ;
} */

.ant-table-thead:hover {
  background-color: black;
}
 :where(.css-dev-only-do-not-override-42nv3w).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-42nv3w).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  background-color: transparent !important;
}
.black-table
  :where(.css-dev-only-do-not-override-nnuwmp).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.black-table
  :where(.css-dev-only-do-not-override-nnuwmp).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: black;
}
.black-table .ant-typography {
  color: white;
}
.black-table .ant-table-placeholder {
  background-color: black !important;
}
.black-table .ant-table-placeholder .ant-empty-description {
  color: rgb(210, 210, 210);
}
.black-input .ant-select-selector {
  background-color: black !important;
  color: white;
}
.black-input .ant-select-arrow {
  color: white !important;
}
.plus-button-add.ant-btn-default {
  background-color: black;
  color: white;
  border-radius: 5px;
}
.plus-button-add.ant-btn-default:hover {
  background-color: black !important;
  color: white !important;
  border-color: 3f96ff !important;
}
.ant-table-container-m {
  /* background-color: #00ffd5; */
  display: flex;
  justify-content: center;

  /* overflow-x: auto; */
}
.monti-m-button {
  display: flex;
  justify-content: flex-end;
}
.monti-m-button button {
  margin-left: 10px;
}
.caharacter-m-mid {
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
}
.price-desc-d {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 15px;
  margin-bottom: 20px;
}
.black-input .ant-input-number-input {
  color: white;
}
.user-select-m {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.market-card-bx {
  padding: 10px;
  background-color: #191a1e;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.market-card-bx img {
  margin-bottom: 10px;
}
.next-btn-f {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.containermn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.containermn {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.containermn svg {
  position: relative;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  transition: all 0.3s;
  fill: #666;
}

.containermn svg:hover {
  transform: scale(1.1);
}

.containermn input:checked ~ svg {
  fill: #ffeb49;
}
.stars-bx-fils {
  display: flex;
}
.input-right-radius .ant-select-selection-placeholder {
  color: #a0a0a0;
}
.input-right-radius .ant-select-selector {
  border-radius: 5px !important;

  height: 36px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background-color: rgb(42, 42, 42) !important;
  color: white !important;
  border-color: transparent !important;
}
.select-left-radius .ant-select-selector {
  border-radius: 5px !important;
  height: 36px !important;

  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background-color: rgb(42, 42, 42) !important;
  color: white !important;
  border-color: transparent !important;
  border-left-color: rgb(61, 61, 61) !important ;
}

.ctype-selected {
  margin-right: 10px;
  margin-top: 5px;
  text-transform: capitalize;
  color: gray;
}
.ctype-selected .close-icon-top-s {
  color: gray;
  background-color: transparent;
  padding: 0px;
  font-size: 16px;
}
.ctype-selected .close-icon-top-s:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: white;
}
.ctype-selected svg {
  background-color: var(--blue2);
  color: white;
  padding: 5px;
  font-size: 25px;
  border-radius: 50%;
}
.gb-tag-brandv {
  width: 90%;
  margin-left: 5%;
  border-radius: 5px;
  margin-bottom: 20px;
  max-width: 160px;
}
.gb-tag-brandv:hover {
  transition: 0.5s;
  transform: scale(1.04);
  cursor: pointer;
}

.create-tab-main-row .name-tg-b .ant-input,
.create-tab-main-row .name-tg-b input {
  color: white;
  border-color: transparent;
  border-radius: 4px !important;
}
.create-tab-main-row .name-tg-b .ant-input:focus,
.create-tab-main-row .name-tg-b input:focus {
  background-color: #373737;
  color: white;
}

.gb-tag-brandv.active-ctype-tag {
  border: 5px solid white;
}
.transition-row .ant-col {
  transition: all 0.4s ease-in-out;
}
.select-type-csc {
  color: white;
  margin-bottom: 20px;
  font-size: 20px;
}
.sel-css-btns .editor-tag-tab {
  background-color: black;
  color: white !important;
}
.sel-css-btns .editor-tag-tab:hover {
  background-color: black !important ;
  color: white !important;
}
.sel-css-btns .editor-tag-tab:active {
  background-color: rgba(255, 255, 255, 0.4) !important ;
  color: white !important;
  border-color: transparent;
}
.sel-css-btns {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: rgba(0, 170, 255, 0.2);
  border-radius: 5px;
}
.sel-css-btns .editor-tag-tab {
  margin: 5px;
}

.up-sel-b-a {
  background-color: rgba(255, 255, 255, 0.1);
  min-height: 200px;
  padding: 20px;
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.up-sel-b-a .ant-checkbox-wrapper span {
  color: white;
}

.btn-uplcs {
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  overflow: hidden;
}

.btn-uplcs::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #212121;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
}

.btn-uplcs:hover {
  color: #e8e8e8;
}

.btn-uplcs:hover::before {
  width: 100%;
}
.detail-text-sc {
  background-color: black;
  padding: 10px;
  border-radius: 5px;
}
.light-input .ant-select-selection-placeholder {
  color: rgb(163, 163, 163);
}
.padding-table td {
  padding: 5px;
}
.button-36 {
  background-image: linear-gradient(
    92.88deg,
    #455eb5 9.16%,
    #5643cc 43.89%,
    #673fd7 64.72%
  );
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 2.2rem;
  padding: 0 10px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-36:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: 0.1s;
}
.header-c-desk {
  width: 100%;
}
.mobile-header-crt {
  display: none;
}

.mobile-header-crt .start-icons-bx.less-width-bx {
  margin-bottom: 10px;
}

.mobile-header-crt .bottom-icons-jh.start-icons-bx.less-width-bx {
  margin-top: 10px;
  margin-bottom: 0px;
}

.mobile-header-crt
  .bottom-icons-jh.start-icons-bx.less-width-bx
  .icon-left-ed.active-i-l-ed {
  border-radius: 3px;
  color: #bedbff;
  background-color: black;
}
.mobile-header-crt .bottom-icons-jh.start-icons-bx.less-width-bx .icon-left-ed {
  width: 25px;
  height: 25px;
}
.mobile-header-crt
  .bottom-icons-jh.start-icons-bx.less-width-bx
  .icon-left-ed
  svg {
  font-size: 14px;
  color: white;
}
.mobile-header-crt .white-color-icon {
  color: white;
}

.landing-img-lf {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}
.landing-img-lf2-ab {
  width: 357px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
  transform: rotate(-12.79deg);
}

.footer-main-c {
  margin: 100px 0px 0px 0px;
  /* border-top: 1px solid rgb(35, 35, 35); */
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.05);
}
.footer-title {
  color: white;
  font-weight: 500;
}
.nav-link {
  color: rgb(205, 205, 205);
}
.nav-link:hover {
  color: white;
}
.page-bx-main-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.videos-uploading-modal {
  position: fixed;
  right: 1rem;
  z-index: 1000;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 10px 10px 0 0;
  width: 276px;
  bottom: 0;
  transition: 0.4s ease;
}

.videos-uploading-modal.active {
  bottom: 0;
}

.videos-uploading-modal.lowered {
  transform: translateY(calc(100% - 45px));
}
.game-show-p1 {
  display: flex;
  width: 100%;
  margin-top: -20px;
  justify-content: center;
}
.game-show-p1 > img {
  margin-right: 20px;
  width: 300px;
  object-fit: contain;
}
.game-show-p1 > div {
  width: 500px;
}
.game-b-c-a .ant-btn-primary {
  background-color: black;
  color: white;
}
.game-b-c-a {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  width: 100%;
}
.slick-prev:before,
.slick-next:before {
  color: black;
}
.assets-bx-h1 .ant-ribbon-wrapper {
  height: 100%;
}
.active-c-b-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.games-4-cards {
  display: flex;
  margin: 20px 0px;
  justify-content: center;
}
.games-4-cards .hover-card-b {
  margin: 10px;
}

.function-card-c.game-fun-card {
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.function-card-c.game-fun-card:hover {
  transition: none;
  transform: none;
}
.function-card-c.game-fun-card img {
  width: 100%;
  height: auto;
  margin-right: 0px;
}
.videos-uploading-modal svg {
  transition: 0.4s ease;
}

.videos-uploading-modal.lowered svg {
  transform: rotateX(180deg);
}

.videos-uploading-modal .head {
  background-color: #111828;
  color: #fff;
  padding: 10px 14px;
  border-radius: 10px 10px 0 0;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.videos-uploading-modal .body {
  background-color: #fff;
}

.videos-uploading-modal .body .uploaded-item {
  padding: 6px 14px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.videos-uploading-modal .body .completed {
  width: 20px;
  height: 20px;
  position: relative;
}

.videos-uploading-modal .body .completed img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.videos-uploading-modal .body .completed img.cross {
  opacity: 0;
}

.videos-uploading-modal .body .uploaded-item:hover .completed img.cross {
  opacity: 1;
  cursor: pointer;
}

.videos-uploading-modal .body .uploaded-item:hover .completed img.tick {
  opacity: 0;
}

.videos-uploading-modal .body .uploaded-item:hover .completed img.error {
  opacity: 0;
}
.uploaded-item:hover .close-bx {
  font-size: 20px;
  color: red;
  position: absolute;
  cursor: pointer;
}
.name-bx-space {
  display: flex;
  margin: 20px 0px;
  align-items: center;
}
.name-bx-space b {
  margin-right: 20px;
}
.space-bxs-s {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.space-bxs-s > div {
  margin: 10px 0px;
  padding: 5px 10px;
}
@media (max-width: 850px) {
  .header-c-desk {
    display: none;
  }
  .mobile-header-crt {
    display: block;
  }
  .header-create-c {
    flex-direction: column;
  }
  .ant-btn.ant-btn-sm {
    font-size: 12px;
  }
  .sel-css-btns {
    flex-wrap: wrap;
  }
  .black-modal .ant-input {
    width: 250px !important;
  }
}
@media (min-width: 768px) {
  .button-36 {
    padding: 0 2.6rem;
  }
}
@media screen and (min-width: 600px) {
  .hide-desktop {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .light-input.main-search-cine {
    max-width: 300px;
  }
  .sign-in-right-content {
    width: 100%;
  }
  .button-tab {
    padding: 5px 5px;
    font-weight: 900;
    font-size: 10px;
  }
  .hide-mobile {
    display: none;
  }
  .feed-view-area .video-feed-mbl-w {
    margin: 10px;
    /* box-shadow: rgba(17, 12, 46, 0.07) 0px 24px 50px 0px; */
    width: 100%;
  }
  .feed-view-area {
    padding-top: 10px;
  }
  .video-thumbnail-img {
    width: 100%;
    height: 220px;
  }
  .large-play-btn {
    margin-top: -140px;
  }

  .create-tab-main {
    padding: 0px;
    padding-bottom: 10px;
  }
  .input-s {
    width: 140px !important;
    padding-left: 8px;
  }
  .container-s .icon-s {
    display: none;
  }
  .container-s {
    margin-left: 0px;
  }
  .my-container-flui .custom-button {
    padding: 5px;
  }
  .container.profile-mn.my-5 {
    margin-top: 10px !important;
  }
  .business-tools-btn {
    display: none;
  }
  .profile-edit-page table {
    width: 100%;
  }
  .tabs-main-bx {
    padding: 10px;
  }

  .table-box-character {
    min-width: 300px !important;
  }
  .black-table .ant-table-thead > tr > th,
  .black-table .ant-table-tbody > tr > th,
  .black-table .ant-table-tbody > tr > td,
  .black-table tfoot > tr > th,
  .black-table tfoot > tr > td {
    padding: 10px 5px;
  }
  .black-table .ant-spin-nested-loading {
    width: 360px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 1400px) {
  .content-ed-main .video-container > div {
    max-width: 500px;
  }
}
@media screen and (max-width: 1100px) {
  .content-ed-main .video-container > div {
    max-width: 400px;
  }
  .video-thumbnail-img {
    width: 80%;
    margin: 0 auto;
  }
}
.ant-upload{
  color: white;
}
.editor-container {
  width: 100vw;

  display: flex;
}
.editor-container .up-bx-mnbcs {
  margin-top: 10px;
}
.editor-container .up-bx-mnbcs {
  justify-content: flex-end;
}

.ant-tabs-nav::before {
  border-bottom-color: rgb(60, 60, 60) !important;
}
.img-card-cont {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.assets-bx-h1 h5 {
  color: white;
}
.connect-top-con {
  margin: 10px 0px 10px 0px;
}
.connect-top-con .ant-col {
  display: flex;
  align-items: center;
}
.left-side-container {
  height: 100%;
  width: 100px;
  min-width: 90px;
  border-right: 1px solid white;
  display: flex;
}
.sidebar {
  overflow-x: hidden;
  position: relative;
  transition: all 0.4s ease-in-out;
}
.img-main-con-cx {
  position: relative;
}
.close-icon-bnc {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.img-main-con-cx:hover .close-icon-bnc {
  opacity: 1;
  transition: all 0.4s ease-in-out;
}
.close-icon-bnc svg {
  border-radius: 50%;
  background-color: white;
  color: var(--blue2);
}
.close-icon-bnc svg:hover {
  color: white;
  background-color: var(--blue2);
  transform: scale(1.2);
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.active-task-bxk img {
  height: 100%;
}

.resizer {
  width: 2px;
  top: 0;
  right: 0;
  cursor: col-resize;
  height: 100%;
  position: absolute;
  background-color: white;
}

.content-editor {
  width: 100%;
  height: 100%;
  background-color: rgb(61, 61, 61);
}
.left-icons-a {
  width: 80px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-left-ed {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(217, 217, 217);
  margin: 5px 10px;
  padding: 5px;
  /* background-color: #42b362; */
  width: 70px;
  border-radius: 10px;
}
.search-bx-c-cine {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* width: 100%; */
  padding: 0% 5%;
  align-items: center;
}
.icon-left-ed b {
  font-size: 11px;
}
.icon-left-ed svg {
  font-size: 18px;
  margin-bottom: 4px;
  margin-top: 1px;
  margin-left: 3px;
}
.icon-left-ed:hover {
  color: white;
  transition: 0.4s;
  cursor: pointer;
}
.icon-left-ed.active-i-l-ed {
  color: black;
  background-image: linear-gradient(to right, orange, yellow);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-dropdown-menu a{
  text-decoration: none;
}
.left-icons-nn {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
}
.start-search-bx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: black;
}
.search-main-ed {
  /* margin-top: 20px; */
  margin-left: 0px;
}
.right-in-left {
  width: 100%;
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid rgb(60, 60, 60);
  margin-right: 10px;
}
.right-in-left input,
.right-in-left .ant-input,
.light-input {
  background-color: rgb(255, 255, 255);

  border-radius: 0px;
  border-color: black;
}

/* .right-in-left input::placeholder,
.right-in-left .ant-input::placeholder,
.light-input::placeholder {
  color: rgb(143, 143, 143);
} */

.light-input .ant-select-selector {
  border-radius: 0px !important;
  border-color: black !important;
}

.ant-select-dropdown-hidden {
  background-color: rgb(42, 42, 42) !important;
}
.center-input-u-img {
  display: flex;
  height: 40px;

  align-items: center;
}
.grey-button {
  background-color: #3a3b3c !important;
  color: white;

  border-color: transparent;
  margin-left: 10px;
}
.grey-button:hover {
  background-color: black !important;
  color: white !important;
  border-color: transparent !important;
}
.videos-left-ed {
  display: flex;
  flex-wrap: wrap;
  max-height: 80%;
  overflow-y: auto;
  align-items: flex-start !important;
  /* background-color: #008300; */
}
.videos-left-ed .video-thumbnail-img {
  width: 100%;
  height: auto;
}
.videos-left-ed .video-thumbnail-img img {
  border-radius: 2px;
}
.videos-left-ed .video-card-story {
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
}
.videos-left-ed .large-play-btn {
  margin-top: -50px;
  width: 32px !important;
  height: 32px !important;
  background: transparent;
  border-color: transparent;
  background-color: rgba(240, 248, 255, 0.343);
}
.videos-left-ed .large-play-btn:hover {
  background-color: white !important;
  border-color: transparent !important;
}
.videos-left-ed .r-atc-incs {
  position: absolute;
  z-index: 20;
  color: var(--blue2);
  font-size: 16px;
}
.videos-left-ed .large-play-btn:hover .r-atc-incs {
  transform: scale(1.1);
  transition: 0.4s;
}
.videos-left-ed .ant-typography {
  margin-bottom: 0px;
  color: white;
  padding: 3px;
}
.img-hover-wc:hover {
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.tags-editor-tb {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  overflow: hidden;
}
.editor-tag-tab {
  /* background-color: rgb(19 59 207 / 50%); */
  color: white;
  border-color: transparent;
  cursor: pointer;
}
.editor-tag-tab.active-tg-wdt {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1)
  );
  color: black;
  border-color: transparent;
  font-weight: 700;
}
.editor-tag-tab.active-tg-wdt.theme-shade-btn {
  background: linear-gradient(to bottom, var(--blue2), var(--blue2));
  color: white;
  border-color: transparent;
  font-weight: 700;
}

.editor-tag-tab.active-tg-wdt:hover {
  transform: scale(1.02);
  transition: 0.4s;
  cursor: pointer;
  color: white !important;
  border-color: transparent !important;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.15)
  ) !important;
}
.red-c {
  color: red;

  font-size: 18px;
}
.yellow-c {
  color: yellow;
  font-size: 16px;
}
.status-bx-hng {
  display: flex;
  justify-content: center;
}
.btn-sh-bcn {
  color: white;
}
.btn-sh-bcn > div {
  margin-bottom: 5px;
}
.btn-sh-bcn b {
  margin-right: 10px;
}
.close-icon-ed {
  display: flex;
  align-items: center;
  height: 100%;
  width: 1px;
}
.close-icon-ed span {
  position: absolute;
  background-color: #3b3b3b;
  padding: 2px;
  z-index: 100;
  right: 0px;
  color: white;
  border-radius: 5px;
}
.sound-thumbnail-img img {
  width: 80px;
  border-radius: 5px;
}
.sound-thumbnail-img {
  display: flex;
}
.sound-thmb-qa {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  width: 100%;
}

.sound-thmb-qa p {
  margin-bottom: 0px;
  font-size: 13px;
}
.bpttpm-icn-wc {
  /* background-color: #a6a6a6; */
  display: flex;
  justify-content: flex-end;
}
.bpttpm-icn-wc svg {
  color: white;
}
.bpttpm-icn-wc svg:hover {
  color: white;
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.video-card-story audio {
  width: 100% !important;
}
.music-thmb-qa {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff14;
  justify-content: center;
  min-height: 80px;
}
.music-thmb-qa p {
  margin-bottom: 0px;
  font-size: 13px;
}
.music-thmb-qa .bpttpm-icn-wc svg {
  font-size: 20px;
  margin-bottom: 5px;
}
.music-thmb-qa .bpttpm-icn-wc svg:hover {
  color: white;
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.card-imagine-create2.editor-create-bx {
  margin-top: 10px;
}
.create-button-con .editor-tag-tab.active-tg-wdt {
  width: 100px;
  margin: 10px 0px 0px 10px;
  padding: 4px 5px;
  display: flex;
  justify-content: center;
}
.create-button-con .editor-tag-tab.active-tg-wdt:hover {
  transform: scale(1.02);
  transition: 0.4s;
  cursor: pointer;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.15)
  );
}

.create-button-con .editor-tag-tab.active-tg-wdt:active {
  background-color: #081e67;
}
.left-side-container .ant-skeleton.ant-skeleton-element .ant-skeleton-button {
  width: 100% !important;
  /* background: linear-gradient(90deg, rgb(255 255 255 / 6%) 25%, rgb(255 255 255 / 15%) 37%, rgb(255 255 255 / 6%) 63%) !important; */
}
.left-side-container .ant-skeleton.ant-skeleton-element {
  height: auto !important;
  width: 100% !important;
}
.left-side-container .eighty-w-sk {
  width: 80px !important;
}
.container8 {
  position: relative;
  --size-button: 40px;
  color: white;
}

.input8 {
  padding-left: var(--size-button);
  height: var(--size-button);
  font-size: 15px;
  border: none;
  color: #fff;
  outline: none;
  width: var(--size-button);
  transition: all ease 0.3s;
  background-color: #191a1e;
  box-shadow: 1.5px 1.5px 3px #0e0e0e, -1.5px -1.5px 3px rgb(95 94 94 / 25%),
    inset 0px 0px 0px #0e0e0e, inset 0px -0px 0px #5f5e5e;
  border-radius: 5px;
  cursor: pointer;
}

.input8:focus,
.input8:not(:invalid) {
  width: 200px;
  cursor: text;
  box-shadow: 0px 0px 0px #0e0e0e, 0px 0px 0px rgb(95 94 94 / 25%),
    inset 1.5px 1.5px 3px #0e0e0e, inset -1.5px -1.5px 3px #5f5e5e;
}

.input8:focus + .icon8,
.input8:not(:invalid) + .icon8 {
  pointer-events: all;
  cursor: pointer;
}

.container8 .icon8 {
  position: absolute;
  width: var(--size-button);
  height: var(--size-button);
  top: 0;
  left: 0;
  padding: 8px;
  pointer-events: none;
}

.container8 .icon8 svg {
  width: 100%;
  height: 100%;
}

.icons-tab {
  color: var(--blue2);
  background-color: rgb(255, 255, 255);
  margin: 0px 5px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons-tab:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
}
.icons-tab svg {
  font-size: 17px;
}
.ant-tabs-tab-active .icons-tab {
  background-color: var(--blue2);
  color: rgb(255, 255, 255);
}
.ant-tabs-tab {
  color: rgb(255, 255, 255);
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}

.ant-tabs-nav::before {
  border-color: transparent !important;
}
.show-content-icon {
  display: flex;
  justify-content: flex-end;
  height: 0px;
}
.show-content-icon svg {
  position: relative;
  top: -33px;
  right: 10px;
  font-size: 25px;
  color: var(--blue2);
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
}
.show-content-icon.video-ixn svg {
  top: 0px;
}
.show-content-icon svg:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
  color: var(--blue2);
  background-color: rgb(255, 255, 255);
}
.show-content-icon svg:active {
  color: black;
  background-color: rgb(255, 255, 255);
  transform: scale(1) !important;
  transition: 0s;
}
.show-content-icon2 {
  display: flex;
  justify-content: space-between;
  height: 0px;
  position: relative;
  top: -15px;
  right: -10px;

  padding: 0px 5px;
}
.show-content-icon2 .icon-img-hover {
  color: var(--blue2);
  opacity: 0.6;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 3px;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.show-content-icon2 .icon-img-hover svg {
  font-size: 16px;
}
.show-content-icon2.video-ixn svg {
  top: 0px;
}
.show-content-icon2 .icon-img-hover:hover {
  transform: scale(1.1);
  transition: 0.5s;
  opacity: 1;
  cursor: pointer;
  color: var(--blue2);
  background-color: rgb(255, 255, 255);
}
.show-content-icon2 svg:active {
  color: black;
  background-color: rgb(255, 255, 255);
  transform: scale(1) !important;
  transition: 0s;
}
.video-card-story .show-hover {
  opacity: 0;
}
.video-card-story:hover .show-hover {
  opacity: 1;
  transition: 0.7s;
}
.content-ed-main {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  position: relative;
}
.content-img-bx .content-img-m {
  max-width: 80%;
  max-height: 420px;
  object-fit: contain;
  border-radius: 5px;
  border: 2px solid rgb(150, 150, 150);
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
}
.blank-page-imhg-c .content-img-m {
  max-width: 100%;
  max-height: 420px;
}
.active-goals-tc .blank-page-imhg-c .content-img-m {
  width: 100%;
  border-radius: 5px;
}
.blank-page-imhg-c {
  position: relative;
}
.bx-iucon-sx {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 420px;
  color: white;
  overflow: hidden;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
}
.content-img-bx .video-container {
  max-width: 100%;
  max-height: 420px;
  border: 2px solid rgb(150, 150, 150);
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
}
.blank-page-video-c {
  max-width: 80%;
  max-height: 420px;
}
.content-img-bx {
  display: flex;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.cards-buttons-smal {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 20px; */
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 5px;
}
.coonetnt-img-bx-m1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cards-buttons-smal button {
  margin: 5px;
}
.t-r-icons-x {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.t-r-icons-x .icon-left-ed {
  padding: 5px;
  width: 42px;
  border-radius: 5px;
  margin: 0px 0px 5px 0px;
}

.center-input-u-img input {
  background-color: rgb(29, 29, 29);
  color: white;
  margin-bottom: 0px;
  padding: 8px 8px;
}
.center-input-u-img input::placeholder {
  color: rgb(143, 143, 143);
}
.center-input-u-img input:focus {
  background-color: rgb(90, 90, 90);
  color: white;
}
.start-mn-mx .center-input-u-img input:focus {
  background-color: rgb(90, 90, 90);
  color: white;
}
.start-card-bx {
  background-color: rgb(29, 29, 29);
  padding: 15px;
  border-radius: 10px;
  margin: 0px 10px;
  width: 200px;
  display: flex;
  flex-direction: column;
  color: white;
  /* box-shadow: rgba(255, 255, 255, 0.1) 0px 10px 20px; */
}
.start-card-bx:hover {
  transform: scale(1.05);
  transition: 0.5s;
  cursor: pointer;
  background-color: var(--blue2);
  /* box-shadow: rgb(0, 0, 255) 0px 10px 15px -5px; */
  color: rgb(255, 255, 255);
}
.cards-buttons-smal .start-card-bx:hover {
  background-color: black;
  box-shadow: none;
}
.cards-buttons-smal .start-card-bx {
  margin: 5px;
  padding: 15px;
}
.cards-buttons-smal .start-card-bx p {
  margin-bottom: 0px;
}
.start-card-bx:active {
  background-color: black;
}
.start-card-bx:hover svg {
  color: white;
  transition: 0.5s;
}
.start-card-bx > div {
  display: flex;
  align-items: flex-start;
}
.start-card-bx svg {
  color: white;
  font-size: 25px;
  margin-right: 15px;
  /* background-color: white; */
  border-radius: 5px;
}
.loader-c-sd svg {
  position: relative;
}
.start-card-bx h5 {
  text-align: center;
  /* color: var(--blue2); */
}
.start-card-bx p {
  text-align: center;
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: 5px;
}
.cards-buttons-smal .start-card-bx svg {
  font-size: 22px;
}
.cards-buttons-smal .start-card-bx h5 {
  font-size: 17px;
}

.blank-area-box {
  height: 350px;
  width: 60%;
  min-width: 500px;
  background-color: #878787;
}
.start-mn-mx {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  background-color: black;
}
.cards-bx-str {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 700px;
}
.editor-container .ant-select .ant-select-arrow {
  color: white;
}
.start-inputo {
  display: flex;
  width: 100%;
  margin-top: 0px;
  margin-bottom: -20px;
  justify-content: flex-start !important;
}
.home-icon0-t {
  color: grey;
  font-size: 30px;
  margin-right: 10px;
  padding: 2px;
  border-radius: 5px;
}
.home-icon0-t:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
  color: white;
  background-color: var(--blue2);
}
.blank-area-box .create-tab {
  background-color: transparent;
  box-shadow: none;

  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
}
.blank-area-box .input-right-radius .ant-select-selector {
  border-radius: 5px !important;
}
.blank-area-box .create-button-con {
  width: 300px;
  margin: 10px auto;
}
.create-img-bx {
  height: 100%;
  width: auto;
  border-radius: 5px;
}
.blank-area-box {
  display: flex;
  justify-content: center;
}
.img-loader-bx {
  width: 300px;
  height: 300px;
  border: 2px solid rgb(150, 150, 150);
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
  background-color: grey;
  border-radius: 5px;
  max-width: 300px;
}
.img-loader-bx .ant-skeleton-image {
  width: 300px !important;
  height: 300px !important;
}
.img-loader-bx .ant-progress-line {
  position: relative;
  top: -40px;
  padding: 10px;
}
.failed-text-c {
  color: rgb(69, 0, 0);
  margin-top: 10px;
  margin-bottom: -10px;
}
.info-elrt-m.ant-alert-info {
  background-color: rgba(0, 0, 0, 0.4);
  border-color: grey;
  color: white;
  max-width: 400px;
  margin-top: 20px;
}
.info-elrt-m.ant-alert-info .ant-alert-message {
  color: white;
}
.info-elrt-m.ant-alert-info .ant-alert-icon {
  color: white !important;
}
.close-icon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  display: none;
  position: relative;
  height: 0px;
}
.home-input-c-c .close-icon {
  display: block;
}
.vector-list-m:hover .close-icon {
  display: block;
}
.close-icon svg {
  padding: 3px;
  border-radius: 3px;
  background-color: black;
  color: white;
  margin-bottom: 5px;
  font-size: 20px;
  position: absolute;
  top: -10px;
  right: 0px;
  z-index: 30;
}

.close-icon svg:hover {
  color: white;
  background-color: red;
  transition: 0.4s;
  cursor: pointer;
}
.close-icon.plus-icon svg {
  margin-top: 10px;
  color: black;
  background-color: white;
}
.close-icon.plus-icon svg:hover {
  background-color: black;
  color: white;
}
.start-card-bx.active-icn-bb {
  background-color: black;
}
.right-icon-edit-c {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 120px;
  border-radius: 0px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  animation: right-p 0.5s ease;
  z-index: 10;
}
@keyframes right-p {
  0% {
    right: -50px;
  }
  100% {
    right: 0;
  }
}
.right-icon-edit-c > div {
  color: white;
  /* background-color: black; */
  margin: 5px 5px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-icon-edit-c > div.actt {
  background-color: var(--blue2);
  color: rgb(255, 255, 255);
}
.right-icon-edit-c > div:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
  background-color: var(--blue2);
  color: rgb(255, 255, 255);
}

.blank-area-box.video-gene .create-tab {
  display: flex;
  flex-direction: row !important;
  background-color: #081e67;
  align-items: center;
}
.blank-area-box.video-gene .duration-box-m {
  margin-top: 0px;
}
.blank-area-box.video-gene .create-button-con {
  width: 100%;
}
.name-tg-b {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}
.name-tg-b .ant-tag {
  text-transform: capitalize;
  margin-right: 0px;
}

.videos-left-ed .ant-ribbon-text {
  text-transform: capitalize;
}

.name-tg-b input,
.name-tg-b .ant-input {
  background-color: #0e0e0e;
}
.videos-left-ed .ant-ribbon-wrapper:hover .ant-ribbon {
  display: none;
}
.delete-icon-c {
  display: flex;
  justify-content: flex-end;
  /* margin-top: -10px; */
  height: 1px;
}
.delete-icon-c b {
  display: none;
  position: absolute;
  z-index: 600;
  top: 10px;
  right: 5px;
  /* background-color: #BC1823; */
}
.videos-left-ed .ant-ribbon-wrapper {
  width: 100%;
}
.videos-left-ed .ant-ribbon-wrapper:hover .delete-icon-c b {
  display: inline;
  color: white;
}
.videos-left-ed .delete-icon-c b :hover {
  color: red;
  cursor: pointer;
  transform: scale(1.1);
}
.sound-thmb-qa.writing-style-input {
  padding-left: 0px;
}
.writing-style-input .name-tg-b {
  flex-direction: column;
}
.play-icon-coive0 {
  min-width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0e0e0e;
  border-radius: 5px;
}
.play-icon-coive0 svg {
  font-size: 25px;
}
.play-icon-coive0 svg:hover {
  color: var(--blue2);
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
}
.play-icon-coive0 svg:active {
  color: black;
}
.block-type-c {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  width: 100%;
}
.block-add-tbk {
  margin: 30px auto;
}
.block-add-tbk td {
  padding: 10px;
}
.icon-planing-c {
  display: flex;
  align-items: flex-end;
}

.icon-planing-c h5 {
  margin-bottom: 0px;
  display: flex;
  height: 20px;
  align-items: flex-end;
  font-size: 13px !important;
  text-transform: capitalize;
  margin-left: 5px;
}
.ant-input-number-handler-wrap {
  display: flex;
  justify-content: flex-end;
}
.icon-planing-c h5 svg {
  font-size: 14px !important;
  margin-right: 5px;
}
.cards-buttons-smal.planing-goals .start-card-bx {
  width: auto;
}
.ant-popover-inner {
  max-width: 300px;
}
.planing-goals.cards-buttons-smal .start-card-bx {
  padding: 8px;
}
.start-card-bx.red-bg-gr {
  background: linear-gradient(-45deg, #9e0000, #330000);
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
}
.start-card-bx.green-bg-gr {
  background: linear-gradient(-45deg, #008300, #ccfffa);
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
}
.start-card-bx.yellow-bg-gr {
  background: linear-gradient(-45deg, #caca00, #828201);
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.icon-planing-c > img {
  /* width: 20px; */
  height: 40px;
  margin: 0px 3px;
  object-fit: cover;
}
.close-icon.space-bb {
  display: flex;
  justify-content: space-between;
}
.close-icon.space-bb > span {
  top: -10px;
  position: relative;
  color: white;
}
/* .bx-iucon-sx {
  background-color: #081e67;
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -400px;

} */
.duration-box-m.start-inputo {
  justify-content: space-between !important;
}
.center-input-u-img .start-card-bx {
  position: relative;
  border-radius: 5px;
}
.loadin-videos-more-c {
  width: 300px;
  padding-top: 10px;
  color: white;
  display: flex;
  justify-content: center;
}
.home-save-ib {
  display: flex;
  /* flex-direction: column; */
}

.editor-tag-tab.active-tg-wdt.save-btn-c {
  margin-right: 10px;
  border-radius: 3px;
}
.projects-con:hover {
  background-color: #081e67;
  cursor: pointer;
  transition: 0.4s;
  border-color: #081e67;
}
.projects-con.active-card-pro {
  background-color: #081e67;
  border-color: #081e67;
}
.slider-main0div {
  width: auto;
  border: 4px solid red;
  margin-right: 10px;
}
.content-editor .slick-prev:before {
  color: white;
  left: 30px;
  position: absolute;
}
.content-editor .slick-next:before {
  color: white;
  right: 120px;
  position: absolute;
}
/* .bx-form-brand.no-radius-input div {
  width: 100%;
} */
.video-thumbnail-imgg.img-top-bx {
  width: 100%;
  justify-content: center;
  display: flex;
}
.video-thumbnail-imgg.img-top-bx img {
  max-width: 400px;
  border-radius: 5px;
  margin: 0 auto;
}
.audio-player-mn-bx {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  padding-bottom: 30px;
  justify-content: center;
}
.arrow-pro-c {
  display: flex;
  justify-content: space-between;
  color: white;
}
.preview-butn-bx {
  background-color: black;
}
.preview-butn-bx .ant-tag {
  margin-right: 0px;
}
.mapboxgl-ctrl {
  display: none;
}
.mapboxgl-ctrl-bottom-left {
  display: none;
}
.user-name-acc-c.user-name-acc-c21 {
  margin: 20px 0px;
}
.page {
  width: 100%;
  height: 100vh;
}
.top-bar-page-c {
  background-color: black;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  width: 100%;
  padding: 5px 10px;
}
.top-inner-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img-bx-draw {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 1024px;
}

.canvas-drwa-img {
  /* width: 100%; */
  height: 100%;
  min-height: 200px;
  background-color: rgba(0, 0, 0, 0.443);

  position: absolute;
  border: 1px solid rgb(151, 151, 151);
  /* background-color: white; */
  z-index: 10000;
}
.position-circle {
  border: 1px solid rgb(108, 108, 108);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  right: 5px;
  top: 5px;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-image-b {
  width: 150px;
  border-radius: 10px;

  max-height: 150px;
}
.brush-size-d {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.brush-size-d b {
  margin-right: 10px;
}
.profile-edit-page.left-dev-form {
  color: white;
}
.profile-edit-page.left-dev-form .ant-input-affix-wrapper {
  background-color: transparent;
  border-color: rgb(74, 74, 74);
}
.profile-edit-page.left-dev-form .ant-input-affix-wrapper svg {
  color: white;
}
.profile-edit-page.left-dev-form b {
  padding-top: 10px;
  padding-right: 10px;
}
.up-sel-b-a .space-between {
  align-items: center;
  margin-top: 30px;
}
.space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.alert-created {
  color: yellow;
  padding: 5px;
  border-radius: 5px;
  background: rgba(0, 170, 255, 0.2);
  /* margin-bottom: -10px; */
  /* margin-top: 10px; */
  /* margin-left: 30px; */
}

.profile-imgc {
  width: 100px;
  border-radius: 5px;
}
.space-btns-c {
  display: flex;
  flex-wrap: wrap;
}
.search-main-ed .block-type-c {
  justify-content: flex-start;
  margin-bottom: 0px;
  margin-top: 10px;
}

.load-more-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.show-content-icon2.show-hover {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #008300;
}
.show-content-icon2.show-hover .icon-img-hover {
  position: relative;
  z-index: 50;
  /* right: 5px; */
}
.video-card-story.new-pro-d {
  display: flex;
  justify-content: center;
  /* background-color: rgba(240, 248, 255, 0.143); */
  padding: 5px;
  align-items: center;
}
.video-card-story.new-pro-d svg {
  margin-left: 5px;
}
.video-card-story.new-pro-d:hover {
  background-color: var(--blue2);
}
.story-pro-name-c {
  display: flex;
  color: white;
  align-items: center;
  border-bottom: 1px solid grey;
}
.home-to-name {
  display: flex;
  align-items: center;
}
.top-20-modal .videos-left-ed {
  max-height: 80vh;
}
.nested-right-c {
  display: flex;
  flex-direction: column;
}
.img-bx-c-com img {
  height: 35px;
  object-fit: contain;
}
.center-input-u-img.input-center-c {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.center-input-u-img.input-center-c .start-card-bx > div {
  padding: 8px;
}
.top-inner-c.top-inner-bl {
  padding: 5px 10px;
}
.or-bx-canvas {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.save-pro-bx h4 {
  font-size: 18px;
}
.save-pro-bx .video-card-story.projects-con {
  margin-right: 0px !important;
}
.save-pro-bx .start-mn-mx {
  flex-direction: row;
}
.back-icon-bx {
  margin-bottom: 10px;
}
.back-icon-bx svg:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.5s;
}
.edit-icn-c {
  margin-left: 5px;
}
.query-name-bx-c {
  display: flex;
  justify-content: space-between;
}
.edit-icn-c:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.5s;
}
.failed-delte-video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 118px;
  color: rgb(207, 0, 0);
}
.red-button {
  background-color: rgb(150, 0, 0);
  border-color: rgb(150, 0, 0);
  color: white;
}
.red-button:hover {
  background-color: rgb(121, 1, 1) !important;
  border-color: rgb(121, 1, 1) !important;
  color: white !important;
}
.red-button:active {
  background-color: rgb(219, 0, 0) !important;
  border-color: rgb(219, 0, 0) !important;
  color: white !important;
}
.delet-btns-h {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.bx-form-brand h6 {
  width: 100%;
}
.delet-btns-h .ant-btn {
  margin-left: 10px;
  margin-top: 20px;
}
.api-key-input-c {
  margin: 12px 0px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}
.create-btn-h > img {
  width: 30px;
}
.green-button {
  background-color: rgb(45, 113, 0);
  border-color: rgb(45, 113, 0);
  color: white;
}
.card-bx-mnbc {
  padding: 5px;
  height: 100%;
}
.card-bx-mnbc div {
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-modal .ant-modal-content {
  box-shadow: 3px 4px #000;
  /* border: 1px solid #000; */
  background-image: linear-gradient(to right, #212121, #494949);

  box-shadow: rgba(0, 0, 0, 0.15) 0px 48px 100px 0px;
}
.ant-modal .ant-modal-content h5 {
  color: white;
}
.no-close-m .ant-modal-close {
  display: none;
}
.card-bx-mnbc div:hover {
  background: var(--blue2);
  transition: 0.4s;
  cursor: pointer;
}
.card-bx-mnbc a {
  color: white;
  text-decoration: none;
}
.card-bx-mnbc div:hover svg {
  margin-right: -5px;
  transition: 0.4s;
}
.card-bx-mnbc h6 b {
  color: #00fc00;
}
.full-w-table table {
  width: 100%;
}
.full-w-table tr {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.no-heigh-div-c.editor-container.special-card-c {
  height: auto;
}
.no-heigh-div-c.editor-container.special-card-c .card21 {
  margin-top: 0px;
}
/* .card-box-center-c{
  display: flex;
  height: 100%;
} */

.card-box-center-c h6 {
  margin-bottom: 10px;
  margin-left: 5px;
}
.green-button:hover {
  background-color: rgb(34, 85, 1) !important;
  border-color: rgb(34, 85, 1) !important;
  color: white !important;
}
.green-button:active {
  background-color: rgb(45, 113, 0) !important;
  border-color: rgb(45, 113, 0) !important;
  color: white !important;
}
.tags-date-area {
  display: flex;
  justify-content: space-between;
}
.tags-date-area .ant-tag svg {
  color: white;
}
.img-2-bcs {
  border-radius: 5px;

  margin: 10px 0px;
}
.left-text-l {
  font-size: 50px;
  line-height: 55px;
  font-weight: 500;
  margin-bottom: 50px;
}
.lefy-bx-login-gt {
  background-color: #fff599;
  border-right: 2px solid #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 75px 115px;
  display: flex;
  min-height: 100vh;
}
.edit-grid-boxs {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 4px 12px;
  /* margin-bottom: 10px; */
  /* background-color: rgba(0, 0, 0, 0.443); */
}
.edit-grid-boxs .ant-col {
  padding: 4px !important;
}
.row-grd-bx {
  margin-bottom: 0px;
  opacity: 0.4;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.row-grd-bx.active-bx {
  background-color: var(--blue2);
  opacity: 0.7;
  border-color: transparent;
}
.row-grd-bx:active {
  background-color: black;
  /* transform: scale(1.05); */
}
.gen-icons-img-c {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 120;
}
.grid-query-bx-x {
  margin-top: 10px;
  padding: 5px;
  align-items: flex-start;
  display: flex;
  justify-content: center;
}
.fp-overflow {
  height: 100%;
}
.fp-watermark {
  display: none;
}
.carousel-bx-c {
  position: absolute;

  z-index: 90;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  left: 10px;
  top: 80px;
  /* padding: 5px; */
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 5px 0px;
}
.carousel-bx-c img {
  width: 40px;
  margin: 4px 8px;
  border-radius: 5px;
  border: 2px solid transparent;
}
.carousel-bx-c img:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s;
  /* transition: ; */
}
.active-imhg-b img {
  transform: scale(1.2);
  border: 2px solid var(--blue2);
  transition: all 0.4s ease-in-out;
}


.left-alert-bcs {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 300px;
}
.left-alert-bcs .ant-alert {
  width: 250px;
}
.tags-date-area table td {
  padding: 5px 10px;
}
.progress-bc {
  position: absolute;
  z-index: 110;
  right: 0px;
  /* background-color: #008300; */
  top: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress-bc.failed-bcv {
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 7px;
}
.video-container.video-container-round {
  height: 100%;
}
.content-ed-main .video-container-round > div > video {
  margin-bottom: -56px !important;
}
.video-thumbnail-img.img-2-sx {
  border: 2px solid rgb(150, 150, 150);
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
}
.video-thumbnail-img.img-2-sx img {
  border-radius: 0px;
}
.video-thumbnail-img.img-2-sx.audio-bx-blank {
  padding: 15px;
  color: white;
  display: flex;
  align-items: center;
  background-color: black;
}
.video-thumbnail-img.img-2-sx.audio-bx-blank > div > img {
  width: 200px;
  height: auto;
  object-fit: contain;
  border-radius: 5px;
  margin-right: 10px;
}
.sound-blank-q-play {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 200px;
  padding-left: 10px;
}
.sound-blank-q-play .ant-typography {
  margin-bottom: 20px;
  color: white !important;
}
.editor-container .create-tab {
  background-color: black;
  border-radius: 0px;
  max-width: 100%;
  max-height: 90vh;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  /* margin-top: 5vh; */

  margin-top: 48px;
}
.editor-container .geneated-main-create {
  background-color: black;
  border-radius: 0px;
  max-width: 100%;
  max-height: 90vh;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: 48px;
}
.editor-container .create-tab-main {
  padding: 0px;
}
.top-create-ed {
  position: absolute;
  background-color: black;
  width: 100%;
  color: white;
  padding: 3px 10px;
  z-index: 200;
}
.planing-goals.start-goals-cs {
  flex-direction: column;
}
.gaols-bx-start {
  display: flex;
  flex-wrap: wrap;
}
.task-d .checkmark {
  margin: 5px;
}
.created-task-d {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.icon-planing-c.icon-planing-c1 {
  display: flex;
}
p.start-g-desc {
  width: 200px;
  margin: 20px 0px;
}
.display-goal-st-s {
  padding: 10px;
}
.display-goal-st-s .blank-page-imhg-c img {
  max-width: 500px;
  border-radius: 5px;
  margin: 0 auto;
}
.display-goal-st-s .blank-page-imhg-c {
  display: flex;
  justify-content: center;
}
.icon-img-hover.actibe-brain-id {
  background-color: var(--blue2);
  color: white;
}
.img-active-brain img {
  /* width: 100px; */
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid white;
  height: 40px;
}
.img-active-brain {
  display: flex;
  flex-direction: row-reverse;
}
.img-active-brain .clear-icon {
  margin-right: 5px;
  margin-left: 0px;
  opacity: 0;
  cursor: pointer;
}
.create-input-fvc2 .main-search-cine {
  transition: 0.5s all ease-in-out;
}
.img-active-brain:hover .clear-icon {
  opacity: 1;
  transition: 0.4s;
}
.create-input-fvc2.popup-input .main-search-cine {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 54px 55px,
    rgba(0, 0, 0, 0.32) 0px -12px 30px, rgba(0, 0, 0, 0.32) 0px 4px 6px,
    rgba(0, 0, 0, 0.37) 0px 12px 13px, rgba(0, 0, 0, 0.19) 0px -3px 5px;
}
.create-input-fvc2 {
  position: relative;
  top: 0;
}
.create-input-fvc2.popup-input {
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
  top: 70px;
  transition: all ease-in-out 0.7s;
}

.taks-card-mn {
  padding: 10px;
  border-radius: 5px;
  height: 100%;
  /* background: linear-gradient(to bottom, #07174e, #040c28); */
  /* color: white; */
}

.card-tsk {
  position: relative;
  width: 100%;
  height: 150px;
  background: lightgrey;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 1s ease-in-out;
}

.background-tsk {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, #07174e, #040c28);
}

.logo-tsk {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  transition: all 0.6s ease-in-out;
}
.task-name-bnm {
  position: absolute;
  bottom: 10px;
  left: 0;
  /* transform: translate(50%, 0%); */
  transition: all 0.6s ease-in-out;
  text-align: center;
  width: 100%;
}
.task-name-bnm1 {
  position: absolute;
  top: 10px;
  right: 10px;
  /* transform: translate(50%, 0%); */
  transition: all 0.6s ease-in-out;
  z-index: 100;
}
.card-tsk:hover .task-gear-i {
  position: absolute;
  top: 10px;
  right: 10px;
  /* transform: translate(50%, 0%); */
  transition: all 0.6s ease-in-out;
  z-index: 120;
  display: block;
  color: white;
  opacity: 1;
}
.card-tsk .task-gear-i {
  opacity: 0;
  display: none;
}
.card-tsk .task-gear-i svg:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.card-tsk:hover .task-name-bnm,
.card-tsk:hover .task-name-bnm1 {
  display: none;
}

.logo-tsk .logo-svg-tsk {
  fill: white;
  width: 30px;
  height: 30px;
}

.icon-tsk {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon-tsk .svg-tsk {
  fill: rgba(255, 255, 255, 0.797);
  /* width: 100%; */
  transition: all 0.5s ease-in-out;
  font-size: 16px;
}

.box-tsk {
  position: absolute;
  padding: 10px;
  text-align: right;
  font-size: 8px;
  color: white;
  background: rgba(255, 255, 255, 0.389);
  border-top: 2px solid rgb(255, 255, 255);
  border-right: 1px solid white;
  border-radius: 10% 13% 42% 0%/10% 12% 75% 0%;
  box-shadow: rgba(100, 100, 111, 0.364) -7px 7px 29px 0px;
  transform-origin: bottom left;
  transition: all 1s ease-in-out;
}

.box-tsk::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.box-tsk:hover .svg-tsk {
  fill: white;
}

.box1-tsk {
  width: 70%;
  height: 70%;
  bottom: -70%;
  left: -70%;
}

.box1-tsk::before {
  background: radial-gradient(
    circle at 30% 107%,
    #1749ed 0%,
    #123cc7 5%,
    #0a2067 60%,
    #07174e 90%
  );
}

.box1-tsk:hover::before {
  opacity: 1;
}

.box1-tsk:hover .icon-tsk .svg-tsk {
  filter: drop-shadow(0 0 2px white);
}

.box2-tsk {
  width: 50%;
  height: 50%;
  bottom: -50%;
  left: -50%;
  transition-delay: 0.2s;
}

.box2-tsk::before {
  background: radial-gradient(
    circle at 30% 107%,
    #1749ed 0%,
    #123cc7 5%,
    #0a2067 60%,
    #07174e 90%
  );
}

.box2-tsk:hover::before {
  opacity: 1;
}

.box2-tsk:hover .icon-tsk .svg-tsk {
  filter: drop-shadow(0 0 2px white);
}

.box3-tsk {
  width: 30%;
  height: 30%;
  bottom: -30%;
  left: -30%;
  transition-delay: 0.4s;
}

.box3-tsk::before {
  background: radial-gradient(
    circle at 30% 107%,
    #1749ed 0%,
    #123cc7 5%,
    #0a2067 60%,
    #07174e 90%
  );
}

.box3-tsk:hover::before {
  opacity: 1;
}

.box3-tsk:hover .icon-tsk .svg-tsk {
  filter: drop-shadow(0 0 2px white);
}

.box4-tsk {
  width: 10%;
  height: 10%;
  bottom: -14%;
  left: -14%;
  transition-delay: 0.6s;
}

.card-tsk:hover {
  transform: scale(1.05);
  border-color: transparent;
}

.card-tsk:hover .box-tsk {
  bottom: -1px;
  left: -1px;
}

.card-tsk:hover .logo-tsk {
  transform: translate(0, 0);
  bottom: 5px;
  right: 5px;
}
.card-tsk:hover .logo-tsk img {
  width: 30px;
  transition: 0.5s;
}
.logo-tsk img {
  width: 80px;
  border-radius: 10px;
}
.task-name-bnm {
  color: white;
}
.txt-title-c {
  position: relative;
  z-index: 130;
}
.active-task-bxk {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.active-task-bxk > img {
  width: 80px;
  border-radius: 5px;
  margin: 0 auto;
}

.active-goals-tc {
  margin: 50px 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.active-goals-tc21 {
  /* max-height: 80vh; */
  /* overflow-y: auto; */
}
.assets-bx-h1 {
  height: 100%;
  width: 100%;
}
.tabs-main-dev {
  display: flex;
}
.arrow-back-task-g {
  font-size: 20px;
  color: white;
  margin-bottom: 20px;
}
.arrow-back-task-g:hover {
  transition: 0.4s;
  cursor: pointer;
  transform: scale(1.1);
}
.display-goal-st-s {
  width: 380px;
  display: flex;
  padding: 15px;
  align-items: center;
  /* overflow: hidden; */
}

.meta-title-cn {
  text-align: center;
  color: white;
  margin-bottom: 10px;
}
.arrow-right-c {
  color: white;
  margin-left: 15px;
  margin-right: -15px;
}
.display-goal-st-s .new-icons-img-voice {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.display-goal-st-s .new-icons-img-voice img {
  width: 100px;
  margin-bottom: 10px;
}
.display-goal-st-s1 {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  align-items: center;
  border-radius: 5px;
}

.light-input.main-search-cine {
  background-color: #1d1d1d;

  padding: 8px 10px;
}
.light-input.main-search-cine:focus {
  background-color: var(--blue2);
}
.task-name-bnm .ant-typography p {
  margin-bottom: 0px;
  color: white;
}
.task-name-bnm .ant-typography {
  margin-bottom: 0px;
  color: white;
  font-size: 12px;
  padding: 0px 5px;
}
.start-icons-bx {
  display: flex;
}
.video-thumbnail-imgg {
  position: relative;
  width: 100%;
  background-color: black;
}
.top-right-popup.progress-card-title {
  left: 10px;
}
.top-right-popup.progress-card-title span {
  background-color: black;
  font-size: 11px;
  text-transform: none;
}
.top-right-popup.progress-card-title .icon-q {
  position: relative;
  z-index: 120;
  background-color: black;
  border-radius: 50%;
  left: 2px;
  font-size: 20px;
  padding: 2px;
}
.search-bx-c-cine .ant-badge-count {
  width: 16px;
  height: 16px;
  min-width: 16px;
  line-height: 15px;
  font-size: 10px !important;

  border-color: transparent;
}
.max-90-vh {
  max-height: 85vh;
  overflow-y: auto;
}
.card-bx-mn-list {
  padding: 5px;
}
.display-goal-stgh {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
.bg-text-clr {
  background: rgba(0, 0, 0, 1);
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
}
.display-goal-stgh .video-thumbnail-imgg img {
  width: 200px;
  object-fit: cover;
}
.display-goal-stgh .video-thumbnail-imgg {
  background-color: transparent;
  width: auto;
}
.list-inner-bx-ui {
  display: flex;
  flex-direction: column;
  height: 180px;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
}
.list-inner-bx-ui > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.video-thumbnail-imgg img {
  width: 100%;
  max-height: 180px;
  min-height: 180px;
  object-fit: cover;
  border-radius: 5px;
  background: linear-gradient(to bottom, #181818, #000000);
  position: relative;
}
.video-thumbnail-imgg .compute-img-tbl {
  min-height: auto;
  width: 30px;
  background: transparent !important;
}
.center-list-bx-c {
  display: flex;
  /* background-color: black; */
  padding: 5px;
  border-radius: 5px;
  align-items: center;
}

.video-thumbnail-imgg.height-img-mx img {
  width: 100%;
  max-height: 800px;
  min-height: 180px;
}
.icons-bx-ri-vd2s {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px; /* Optional: Rounded corners */
}
.icons-bx-ri-vd2s svg {
  /* color: var(--blue2); */
  font-size: 17px;
}

.icons-bx-ri-vd2s .ant-btn:hover {
  background-color: black;
}
.icons-bx-ri-vd2s .ant-btn svg {
  margin-top: -4px;
}
.icons-bx-ri-vd2s .ant-btn:hover svg {
  color: white;
  border-color: black !important;
  margin-top: -4px;
}

.create-input-fvc {
  display: flex;
  flex-direction: column;
}
.create-input-fvc2 {
  display: flex;
  align-items: center;
}
.create-input-fvc .icon-left-ed {
  width: auto;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 5px;
}
.create-input-fvc .icon-left-ed svg {
  font-size: 16px;
}
.home-img-card {
  width: 100%;
  height: auto;
  padding: 5px;
}
.blank-page-imhg-cgh {
  width: 100%;
  height: 100%;
}
.blank-page-imhg-cgh img {
  width: 100%;
  max-height: 180px;
  min-height: 180px;
  object-fit: cover;
  border-radius: 5px;
}
.video-thumbnail-imgg:hover {
  transform: scale(1.02);
  transition: 0.4s;
  cursor: pointer;
}
.video-thumbnail-imgg2s {
  position: absolute;
  z-index: 500;
  background-color: black;
  /* border: 1px solid grey; */
  transition: all 0.6s ease-in-out;
  margin-left: -10px;
  height: 100%;

  /* background-color: #008300; */
  /* width: 150%;
  padding: 10px;
  margin-left: -30%; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.video-thumbnail-imgg2s .video-container {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  /* background-color: #008300; */
  /* background-color: var(--bgBlack2); */
}
.video-thumbnail-imgg2s audio {
  width: 100% !important;
}
.start-icons-bx.less-width-bx {
  display: flex;

  align-items: center;
}
.start-icons-bx.less-width-bx .icon-left-ed {
  width: 34px;
  height: 34px;
  justify-content: center;
  margin: 0px 5px;
  display: flex;
}
.start-icons-bx.less-width-bx .icon-left-ed svg {
  margin-bottom: 0px;
}
.progress-bc .ant-progress-inner {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.progress-bc .ant-progress-circle-path {
  stroke: none !important;
}

.header-create-c {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0px 2%;
}
.bottom-popup {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
}
.bottom-popup span {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 10px;
  font-size: 13px;
  border-radius: 5px;
  text-transform: capitalize;
}
.top-right-popup {
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;

  right: 10px;
}
.top-center-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
}
.bottom-right-popup {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
}
.top-right-popup2 {
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;

  right: 10px;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 5px;
  text-transform: capitalize;
  background-color: rgba(255, 255, 255, 0.289);
  color: black;
}
.top-left-popup {
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;

  left: 10px;
}
.centered-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom-left-popup {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;

  left: 10px;
}
.bottom-left-popup .ant-tag {
  position: relative;
  z-index: 120;
}
.bottom-left-popup > span {
  text-transform: capitalize;

  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 10px;
  font-size: 13px;
  border-radius: 5px;
}
.top-right-popup > span {
  text-transform: capitalize;

  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 10px;
  font-size: 13px;
  border-radius: 5px;
}
.c-type-icn b {
  background-color: #0000001f;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  border-radius: 5px;
  text-transform: capitalize;
}
.top-center-popup.top-2-card-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-center-popup.top-2-card-c > span {
  margin-bottom: 10px;
}
/* .c-type-icn b {
  text-transform: capitalize;
  background: -webkit-linear-gradient(180deg,white, #9e0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
.top-center-popup > span {
  text-transform: capitalize;

  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 10px;
  font-size: 13px;
  border-radius: 5px;
}
.display-goal-st-s1:hover .hide-hover {
  display: none;
}

.show-hover {
  display: none;
  position: relative;
  z-index: 100;
}
.display-goal-st-s1:hover .show-hover {
  display: inline;
  opacity: 1;
}
.top-right-popup .show-hover svg,
.circular-icon {
  font-size: 24px;
  /* margin-left: 5px; */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 3px;
  padding: 4px;
}
.hover:hover {
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 52px;
  margin-top: -20px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
.edit-block-modal > div {
  margin: 20px 0px;
}
.block-s-c {
  display: flex;
  align-items: center;
}
.block-s-c > svg {
  font-size: 22px;
  margin-right: 10px;
}
.block-s-c > svg:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s;
}
.attach-popup-c {
  position: absolute;
  right: 0;
  z-index: 100;
  cursor: pointer;
  background-color: black;
  padding: 5px;
  font-size: 25px;
  border-radius: 5px;
}
.attach-popup-c:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s;
}
.video-thumbnail-imgg .attach-popup-c {
  margin-left: 5px;
}
.right-area-det-m {
  background-color: #3a3b3c;
  height: 100%;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  min-height: 50vh;
  color: white;
}
.tags-date-area.right-area-det-m td {
  padding: 10px;
}
.tags-date-area.right-area-det-m {
  display: flex;
  justify-content: center;
  width: 100%;
}
.active-task-bxk .show-hover {
  display: inline;
}
.active-task-bxk .coonetnt-img-bx-m1 {
  position: relative;
}
.active-task-bxk .top-right-popup {
  position: relative;
  display: flex;
  justify-content: flex-end;
  right: 0;
  height: 0px;
  margin-top: -24px;
}

.active-task-bxk .top-right-popup .show-hover svg,
.active-task-bxk .circular-icon {
  background-color: rgba(255, 255, 255, 0.689);
  color: black;
  /* margin-bottom: 20px; */
}
.image-top-r {
  position: relative;
  top: -40px;
}
.voice-top-r {
  /* width: 100%; */
  position: absolute;
  top: -20px;
  right: 0px;
}

.cat-hover-det1 {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;

  padding: 12px;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.484);
}
.category-text-p {
  color: white;
}
.cat-hover-det {
  /* opacity: 0; */
  position: absolute;
  background-color: rgba(0, 0, 0, 0.484);
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  bottom: 0;
  border-radius: 5px;
  padding: 10px;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-goal-st-s1:hover .cat-hover-det {
  opacity: 1;
  transition: 0.4s;
}
.display-goal-st-s1:hover .cat-hover-det1 {
  opacity: 0;
}
.category-text-d {
  color: white;
  margin-top: 20px;
  text-transform: capitalize;
  font-size: 20px;
}
.no-heigh-div-c.editor-container {
  max-width: 500px;
  max-height: 250px;
  margin: 0 auto;

  width: 100%;
}
.no-heigh-div-c.editor-container .ant-btn {
  margin-top: 0px !important;
}
.download-show-img img {
  width: 100%;
  border-radius: 5px;
}
.black-query-bc {
  font-size: 18px;
  text-transform: capitalize;
}
.black-query-bc {
  display: flex;
  justify-content: space-between;
}
.black-query-bc .ant-tag {
  color: white;
}
.black-query-bc .ant-tag:hover {
  transform: scale(1.03);
  transition: 0.4s;
  cursor: pointer;
}
.black-query-bc .ant-tag:active {
  background-color: gray;
}
.images-main-p .video-thumbnail-imgg img {
  min-height: 100px;
  height: 100%;
}
.img-height-adjusted .video-thumbnail-imgg {
  height: 100%;
}
.img-height-adjusted .video-thumbnail-imgg img {
  min-height: 50px;
  height: 100%;
}
.related-bx-d {
  padding: 10px 0px;
}
.tags-date-area.settings-bz-c {
  background-color: rgba(100, 100, 111, 0.1);
  margin: 40px 0px;
  padding: 20px;
  border-radius: 5px;
}
.tags-date-area.settings-bz-c td {
  color: white;
  padding: 10px;
}
#lc-checkbox {
  display: none;
}

.lc-switch {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: rgb(99, 99, 99);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(126, 126, 126);
  box-shadow: 0px 0px 3px rgb(2, 2, 2) inset;
}
.lc-switch svg {
  width: 1em;
}
.lc-switch svg path {
  fill: rgb(48, 48, 48);
}
#lc-checkbox:checked + .lc-switch {
  box-shadow: 0px 0px 1px var(--blue2) inset, 0px 0px 2px var(--blue2) inset,
    0px 0px 10px var(--blue2) inset, 0px 0px 40px var(--blue2),
    0px 0px 100px var(--blue2), 0px 0px 5px var(--blue2);
  border: 2px solid rgb(255, 255, 255);
  background-color: rgb(146, 180, 184);
}
#lc-checkbox:checked + .lc-switch svg {
  filter: drop-shadow(0px 0px 5px var(--blue2));
}
#lc-checkbox:checked + .lc-switch svg path {
  fill: rgb(255, 255, 255);
}
.so-login-links b {
  margin: 0px 10px;
  color: #d7d7d7;
}
.so-login-links b:hover {
  color: white;
  cursor: pointer;
}
.editor-tag-tab.active-tg-wdt.theme-btn-c {
  background-color: #008300;
}
.editor-tag-tab.active-tg-wdt.theme-btn-c2 {
  background-color: var(--blue2);
  color: white;
}
.editor-tag-tab.active-tg-wdt.theme-btn-c2 svg {
  margin-right: 10px;
}
.editor-tag-tab.active-tg-wdt.theme-btn-c .arrow-right-c {
  margin-right: 0px;
  margin-left: 10px;
}
.table-space-s tr td {
  padding: 15px 20px;
}
.table-space-s tr th {
  padding: 5px 20px;
}
.icons-td-c svg {
  font-size: 18px;
}
/* .table-space-s .table-row-cc:hover{
  background-color: rgba(255, 255, 255, 0.1);
} */
.table-space-s th {
  background: radial-gradient(
    circle at 30% 107%,
    #bc182378 0%,
    #95131c68 5%,
    #700f1570 60%,
    #34070a67 90%
  );
}
span.transparent-meta-title {
  background-color: rgba(0, 0, 0, 1) !important;
  color: white;
}
.compute-img-bxs {
  width: 50px;
  border-radius: 5px;
  height: auto;
}
.alert-input-bx-v {
  display: flex;
  padding: 0px 10px;
}
.alert-input-bx-v .ant-input {
  max-width: 500px;
}

.bauble_box .bauble_label {
  background: #2c2;
  background-position: 62px 5px;
  background-repeat: no-repeat;
  background-size: auto 5px;
  border: 0;
  border-radius: 50px;
  box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.4),
    0 -1px 0px rgba(0, 0, 0, 0.2), inset 0 -1px 0px #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0;
  height: 40px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 90px;
}

.bauble_box .bauble_label:before {
  background-color: rgba(255, 255, 255, 0.2);
  background-position: 0 0;
  background-repeat: repeat;
  background-size: 30% auto;
  border-radius: 50%;
  box-shadow: inset 0 -5px 25px #050, 0 10px 20px rgba(0, 0, 0, 0.4);
  content: "";
  display: block;
  height: 30px;
  left: 5px;
  position: absolute;
  top: 6px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 30px;
  z-index: 2;
}

.bauble_box input.bauble_input {
  opacity: 0;
  z-index: 0;
}

/* Checked */
.bauble_box input.bauble_input:checked + .bauble_label {
  background-color: #c22;
}

.bauble_box input.bauble_input:checked + .bauble_label:before {
  background-position: 150% 0;
  box-shadow: inset 0 -5px 25px #500, 0 10px 20px rgba(0, 0, 0, 0.4);
  left: calc(100% - 35px);
}
.black-collapse {
  width: 100%;
}
.black-collapse .ant-collapse-header {
  color: white !important;
  background-color: var(--bgBlack2);
}
.black-collapse.ant-collapse > .ant-collapse-item {
  border-radius: 5px;
  margin-bottom: 5px;
  border-bottom-color: transparent;
}
.compute-img-tbl {
  width: 40px;
}
.learn-more-btn-exp {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.active-conn-c {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: flex-end;
  border-bottom: 1px solid rgb(60, 60, 60);
  margin-bottom: 20px;
}
.active-conn-c img {
  margin: 0px 5px;
  border-radius: 5px;
}
.models-connected-c {
  display: flex;
  align-items: center;
}
.video-thumbnail-imgg .model-urls-img-c {
  width: 20px !important;
  height: 20px !important;
  max-height: 20px !important;
  min-height: 20px !important;
  margin-right: 5px;
  border-radius: 5px;
}
.dropdown-img {
  height: 18px;
  width: 18px;
  object-fit: contain;
  padding: 1px;
  background-color: black;
  margin-right: 5px;
  border-radius: 2px;
}
.image-table-loader {
  position: absolute;
  z-index: 15;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin-top: -200px;
  background-color: black;
  border-radius: 5px;
  width: 120px;
}
.c-type-icn svg {
  font-size: 20px;
  cursor: help;
}
.csv-adjusted-table th {
  text-wrap: nowrap;
}
.csv-adjusted-table {
  width: 100%;
  overflow: auto;
  height: 80vh;
}
.active-conn-c.behaveior-c {
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-start;
}
.behavior-table-add {
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.behavior-table-add td {
  padding: 10px;
}
.ant-switch .ant-switch-inner {
  background-color: rgb(42, 42, 42);
}
.ant-switch.ant-switch-checked .ant-switch-inner {
  background-color: var(--blue2);
}
.behavior-table-add h6 {
  margin-bottom: 0px;
}
.behaviour-cards-bx {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.behaviour-cards {
  display: flex;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  background: linear-gradient(to bottom, rgb(246 246 246), rgb(198 198 198));
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  height: 100%;
  flex-direction: column;
}
.date-bx-bc {
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
}

.behaviour-cards b {
  font-size: 12px;
}
.behaviour-cards:hover .show-hover {
  opacity: 1;
  display: inline;
  position: absolute;
}
.behaviour-cards:hover .opacity-h {
  opacity: 0;
}
.behaviour-cards.query-box-beh:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.4s;
  z-index: 10;
  background: linear-gradient(to bottom, #131313, #131313);
}
.behaviour-cards.selected-bx-c {
  /* background-color: rgb(40, 59, 103);
  background-color: rgb(40, 59, 103); */
  background: linear-gradient(to bottom, rgb(111 170 84), rgb(19 54 3));
  color: white;
  /* border-top-color: #6faa54;
  border-left-color: #7fbfe9;
  border-right-color: #ffcf47;
  border-bottom-color: #ff5f6b; */
}
.show-hover.configure-btn {
  position: absolute;
  bottom: -20px;
}
.gear-icon-c {
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 10;
}
.gear-icon-c:hover {
  transform: scale(1.1);
  transition: 0.4s;
  cursor: pointer;
}
.video-loading-guide {
  width: 200px;
}
.app-name-page {
  margin: 10px 0px 10px 20px;
  color: white;
}
.learn-more-btn-exp.between-flx {
  justify-content: space-between;
}
/* inspired form gumroad website */
.button-787 {
  --bg: #bc1823;
  --hover-text: white;
  color: #fff;
  cursor: pointer;
  border: 1px solid var(--bg);
  border-radius: 4px;
  padding: 0.3em 1em;
  background: var(--bg);
  transition: 0.2s;
  margin-left: 10px;
}

.button-787:hover {
  transform: translate(-0.25rem, -0.25rem);
  background: #ccfffa;
  color: white;
  box-shadow: 0.25rem 0.25rem var(--bg);
}

.button-787:active {
  transform: translate(0);
  box-shadow: none;
}
.video-generate-load.video-container {
  display: flex;
  align-items: center;
  height: 100%;
}
.video-generate-load.video-container > div {
  height: 100% !important;
}
.video-generate-load.video-container > div video {
  height: 100%;
  background-color: #07174e;
  object-fit: cover;
}
.models-tabs-box-mnb {
  display: flex;
  justify-content: center;
  width: 100%;
}

.models-tabs-box-mnb button {
  margin: 10px;
}
.ant-drawer-content-wrapper {
  margin: 15px;
}
.ant-drawer-content {
  border-radius: 10px;
}
.black-drawer.ant-drawer-content {
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(119, 119, 119);
  color: white;
}
.black-drawer.ant-drawer-content .ant-drawer-close {
  color: white;
}
.huminoid-img-t {
  width: 100px;
}
.icon-add-plus-c {
  margin-right: 0px !important;
  font-size: 26px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.icon-add-plus-c:hover {
  background-color: var(--blue2);
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
  cursor: pointer;
  color: black;
}
.hover-theme-click:hover {
  background-color: black;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  transform: scale(1.01);
}
.icon-add-plus-c svg {
  margin-right: 0px;
}
.left-area-active-conn {
  display: flex;
  align-items: center;
}
.behaviour-cards-bx.behavrious-tabs-c {
  border-right: 1px solid rgb(60 60 60);
  border-left: 1px solid rgb(60 60 60);
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 10px;
  padding-left: 10px;
}
.flex-center {
  display: flex;
  justify-content: center;
  width: 100%;
}
.help-text-con {
  display: flex;
}
.help-text-con h5 {
  margin-left: 20px;
}
.card-grey-bgc {
  background: linear-gradient(to bottom, #252525, #080808);
  border-radius: 5px;
}
.red-gradient-card .card-grey-bgc {
  background: linear-gradient(to bottom, #dd0108, #110000);
  box-shadow: 0px 0px 1px #dd0108;
}
.green-gradient-card .card-grey-bgc {
  background: linear-gradient(to bottom, #01a30b, #011100);
  box-shadow: 0px 0px 1px #01a30b;
}
.preview-input-c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.preview-input-c .ant-input {
  margin-bottom: 10px;
}
.preview-img-c-m {
  width: 200px;
  height: auto;
  border-radius: 5px;
  margin-top: 10px;
}
.heading-h-table {
  display: flex;
  align-items: flex-start;
}
.behav-main-cardc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.behaviour-cards-img {
  width: 100%;
  max-width: 100px;
  margin: 0px 0px;
  border-radius: 5px;
}
.behav-main-cardc {
  padding: 0px 10px;
}

.behaviour-cards-bx .behav-main-cardc .behaviour-cards {
  margin: 10px 0px;
}
.behaviour-cards.img-cards-beh {
  padding: 5px;
}
.behaviour-cards.img-cards-beh b {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #00000073;
  padding: 5px;
  border-radius: 5px;
}
.generate-behav {
  margin-right: 20px;
}
.selected-img-gen-c {
  height: 32px;
  border-radius: 5px;
}
.name-con-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 5px;
}
.name-con-img img {
  width: 70px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.name-con-img b {
  font-size: 12px;
}

.skills-hover-b {
  margin-left: 10px;
  cursor: help;
}

.list-con-card.ant-card {
  background: transparent;
  width: 100%;
  color: white;
  border-color: #2a2a2a;
  border-radius: 5px;
}
.list-con-card.ant-card * {
  border-color: #2a2a2a;
}
.ant-card .ant-card-actions {
  background-color: transparent;
}
.list-con-card.ant-card .ant-card-cover img {
  height: 150px;
  object-fit: contain;
  padding: 15px 0px;
  background-color: #2a2a2a;
}
.list-con-card.ant-card .ant-card-meta-title,
.list-con-card.ant-card .ant-card-meta-description,
.list-con-card.ant-card .anticon {
  color: white;
}
.list-con-card.ant-card .ant-card-actions > li > span > .anticon {
  color: white;
}
.list-con-card.ant-card .ant-card-actions > li > span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-con-card.ant-card .ant-card-actions > li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-con-card.ant-card .ant-card-meta-title {
  font-size: 14px;
}
.toggle-button-cover {
  display: table-cell;
  position: relative;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button-r {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  overflow: hidden;
  background-color: #2a2a2a;
}

.checkbox-r {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer-r {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

.button-r,
.button-r .layer-r {
  border-radius: 100px;
}

#button-3 .knobs:before {
  content: "OFF";

  position: absolute;
  top: 4px;
  left: 4px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 6px;
  background-color: red;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#button-3 .checkbox-r:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

#button-3 .checkbox-r:checked:active + .knobs:before {
  margin-left: -26px;
}

#button-3 .checkbox-r:checked + .knobs:before {
  content: "ON";

  left: 42px;
  background-color: green;
}

#button-3 .checkbox-r:checked ~ .layer-r {
  background-color: #fcebeb;
}

.button-bhv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  gap: 2px;
  height: 36px;

  border: none;
  /* background: #2a2a2a; */
  border-radius: 20px;
  cursor: pointer;
  background-color: black;
  color: white;
}

.lable-bhv {
  font-family: sans-serif;
  letter-spacing: 1px;
  font-size: 10px;
}

.button-bhv:hover .svg-icon-bhv {
  animation: spin-bhv 2s linear infinite;
}

@keyframes spin-bhv {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.black-drawer .card21 {
  margin-left: 5%;
  width: 90%;
}

.card-m-c-b {
  --font-color: #fefefe;
  --font-color-sub: #7e7e7e;
  --bg-color: #111;
  --main-color: #fefefe;
  --main-focus: #2d8cf0;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  border: 2px solid #2a2a2a;
  box-shadow: 4px 4px 4px #2a2a2a;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  gap: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.card-m-c-b:last-child {
  justify-content: flex-end;
}

.card-img-m-c-b {
  /* clear and add new css */
  transition: all 0.5s;
  display: flex;
  justify-content: center;
}

.card-img-m-c-b .img-m-c-b {
  /* delete */
  transform: scale(1);
  position: relative;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-top-left-radius: 80px 50px;
  border-top-right-radius: 80px 50px;
  border: 2px solid black;
  background-color: #228b22;
  background-image: linear-gradient(
    to top,
    transparent 10px,
    rgba(0, 0, 0, 0.3) 10px,
    rgba(0, 0, 0, 0.3) 13px,
    transparent 13px
  );
}

.card-img-m-c-b .img-m-c-b::before {
  /* delete */
  content: "";
  position: absolute;
  width: 65px;
  height: 110px;
  margin-left: -32.5px;
  left: 50%;
  bottom: -4px;
  background-repeat: no-repeat;
  background-image: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.7) 30%,
      transparent 30%
    ),
    linear-gradient(
      to top,
      transparent 17px,
      rgba(0, 0, 0, 0.3) 17px,
      rgba(0, 0, 0, 0.3) 20px,
      transparent 20px
    ),
    linear-gradient(to right, black 2px, transparent 2px),
    linear-gradient(to left, black 2px, transparent 2px),
    linear-gradient(to top, black 2px, #228b22 2px);
  background-size: 60% 10%, 100% 100%, 100% 65%, 100% 65%, 100% 50%;
  background-position: center 3px, center bottom, center bottom, center bottom,
    center bottom;
  border-radius: 0 0 4px 4px;
  z-index: 2;
}

.card-img-m-c-b .img-m-c-b::after {
  /* delete */
  content: "";
  position: absolute;
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  margin-left: -14px;
  left: 50%;
  top: -13px;
  background-repeat: no-repeat;
  background-image: linear-gradient(80deg, #ffc0cb 45%, transparent 45%),
    linear-gradient(-175deg, #ffc0cb 45%, transparent 45%),
    linear-gradient(80deg, rgba(0, 0, 0, 0.2) 51%, rgba(0, 0, 0, 0) 51%),
    linear-gradient(-175deg, rgba(0, 0, 0, 0.2) 51%, rgba(0, 0, 0, 0) 51%),
    radial-gradient(
      circle at center,
      #ffa6b6 45%,
      rgba(0, 0, 0, 0.2) 45%,
      rgba(0, 0, 0, 0.2) 52%,
      rgba(0, 0, 0, 0) 52%
    ),
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 48%,
      rgba(0, 0, 0, 0.2) 48%,
      rgba(0, 0, 0, 0.2) 52%,
      rgba(0, 0, 0, 0) 52%
    ),
    linear-gradient(
      65deg,
      rgba(0, 0, 0, 0) 48%,
      rgba(0, 0, 0, 0.2) 48%,
      rgba(0, 0, 0, 0.2) 52%,
      rgba(0, 0, 0, 0) 52%
    ),
    linear-gradient(
      22deg,
      rgba(0, 0, 0, 0) 48%,
      rgba(0, 0, 0, 0.2) 48%,
      rgba(0, 0, 0, 0.2) 54%,
      rgba(0, 0, 0, 0) 54%
    );
  background-size: 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%,
    100% 75%, 100% 95%, 100% 60%;
  background-position: center center;
  border-top-left-radius: 120px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 70px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(45deg);
  z-index: 1;
}

.card-title-m-c-b {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: var(--font-color);
}

.card-subtitle-m-c-b {
  font-size: 14px;
  font-weight: 400;
  color: var(--font-color-sub);
}

.card-divider-m-c-b {
  width: 100%;
  border: 1px solid var(--main-color);
  border-radius: 50px;
}

.card-footer-m-c-b {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-price-m-c-b {
  font-size: 20px;
  font-weight: 500;
  color: var(--font-color);
}

.card-price-m-c-b span {
  font-size: 20px;
  font-weight: 500;
  color: var(--font-color-sub);
}

.card-btn-m-c-b {
  height: 35px;
  background: var(--bg-color);
  border: 2px solid var(--main-color);
  border-radius: 5px;
  padding: 0 15px;
  transition: all 0.3s;
}

.card-btn-m-c-b svg {
  width: 100%;
  height: 100%;
  fill: var(--main-color);
  transition: all 0.3s;
}

.card-img-m-c-b:hover {
  transform: translateY(-3px);
}

.card-btn-m-c-b:hover {
  border: 2px solid var(--main-focus);
}

.card-btn-m-c-b:hover svg {
  fill: var(--main-focus);
}

.card-btn-m-c-b:active {
  transform: translateY(3px);
}
.card-m-c-b > img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.editor-tag-tab.active-tg-wdt.yellow-btn-c {
  background: linear-gradient(
    to bottom,
    rgba(254, 250, 0, 0.866),
    rgba(164, 176, 0, 0.828)
  );
}

.btn-yel-c {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica, "sans-serif";
  transition: all 0.2s;
  padding: 5px 10px;
  border-radius: 100px;
  background: #cfef00;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.btn-yel-c:hover {
  background: #c4e201;
}

.btn-yel-c > svg {
  /* width: 34px; */
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.btn-yel-c:hover svg {
  transform: translateX(5px);
}

.btn-yel-c:active {
  transform: scale(0.95);
}
.no-padding-content .ant-collapse-content-box {
  padding: 0px !important;
}
.editor-tag-tab.active-tg-wdt.yellow-button-c {
  background: linear-gradient(to bottom, lime, rgb(77, 254, 77));
  color: black;
}
.h-create-s-c {
  display: flex;
  align-items: center;
}
a > .btn-yel-c {
  background: linear-gradient(to bottom, rgb(65 96 169), rgb(18, 27, 48));
  border-radius: 5px;
  color: white;
}
a > .btn-yel-c:hover {
  background: linear-gradient(to bottom, rgb(65 96 169), rgb(18, 27, 48));
}
.chat-bx-input-c {
  display: flex;
  align-items: flex-end;
  /* margin-top: -20px; */
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0px 10px;
  padding-bottom: 10px;
  color: white;
  padding-top: 5px;
  left: 0px;
}

.chat-bx-input-c .icon-left-ed {
  margin: 0px;
  border-radius: 15px;
  margin-left: 3px;
  padding: 6px 4px 3px 4px;
}
.pen-title {
  color: #ffffff;
  margin-bottom: 0;
  text-align: center;
  text-shadow: 1px 1px 0 #000;
}

.pen-description {
  color: #ffffff;
  margin-bottom: 3rem;
  text-align: center;
  text-shadow: 1px 1px 0 #000;
}
.pen-description a {
  color: #ffffff;
  text-decoration: underline;
}

.pricing-section a {
  text-decoration: none;
}
.pricing-section a:hover {
  text-decoration: underline;
}

.pricing-section img {
  height: auto;
  max-width: 100%;
}

.wrap {
  max-width: 85.375rem;
  margin: 0 auto;
}

.image-as-background {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.screen-reader-text {
  height: auto;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}
.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
  height: auto;
  width: auto;
  background-color: #000000;
  border-radius: 2px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #ffffff;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  left: 0.3125rem;
  line-height: normal;
  padding: 0.9375rem 1.4375rem 0.875rem;
  text-decoration: none;
  top: 0.3125rem;
  z-index: 5;
}

@media screen and (min-width: 64rem) {
  .wrap {
    padding: 1.25rem;
  }
}

.pricing-section {
  color: #ffffff;
}

.pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 1.875rem;
  width: 100%;
}

.pricing-item {
  align-items: stretch;
  display: flex;
  flex: 0 1 20.625rem;
  flex-direction: column;
  position: relative;
  text-align: center;
}

.pricing-feature-list {
  text-align: left;
}

.pricing-action {
  background: none;
  border: none;
  color: inherit;
}
.pricing-action:focus {
  outline: none;
}

.pricing-theme .pricing-item {
  background: rgba(255, 255, 255, 0.08);
  border: 0.0625rem solid rgba(255, 255, 255, 0.4);
  border-radius: 0.625rem;
  color: #ffffff;
  cursor: default;
  margin-bottom: 1.25rem;
  transition: border-color 0.3s, background 0.3s;
  margin: 10px;
}

.pricing-theme .pricing-item:hover {
  border: 0.0625rem solid white;
  background: rgba(255, 255, 255, 0.18);
}
.pricing-theme .pricing-title {
  font-size: 1.75rem;
  font-weight: 400;
  margin: 0.5rem 0;
  padding: 1.5rem;
  position: relative;
}
.pricing-theme .pricing-title:after {
  position: absolute;
  bottom: 0;
  left: 35%;
  height: 0.0625rem;
  width: 30%;
  background: #ffffff;
  content: "";
}
.pricing-theme .pricing-price {
  font-size: 3rem;
  font-weight: 400;
  /* padding: 1.125rem 0 0 0; */
  position: relative;
  z-index: 1;
}
.pricing-theme .pricing-currency {
  font-size: 1.625rem;
  vertical-align: super;
}
.pricing-theme .pricing-sentence {
  margin: 0 auto;
  padding: 0.875rem 1.25rem;
  font-size: 1rem;
}
.pricing-theme .pricing-feature-list {
  font-size: 1.1rem;
  list-style: none;
  margin: 0 1.875rem;
  padding: 10px 0;
}
.pricing-theme .pricing-feature {
  line-height: 1.4;
}
.pricing-theme .pricing-feature:before {
  content: "✓";
  display: inline-block;
  padding: 0 0.875rem 0 0;
  vertical-align: middle;
}
.pricing-theme .pricing-action {
  border-radius: 0.3125rem;
  flex: none;
  font-weight: 700;
  margin: auto 1.25rem 1.25rem;
  padding: 1rem 1.25rem;
  transition: background 0.3s;
}
.m-y-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-y-switch b {
  font-size: 17px;
  margin: 10px;
}
.user-q-chat {
  padding-left: 15%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.user-q-chat > div {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 5px;
  background-color: #bedbff;
  border-top-right-radius: 0px;
}
.biling-m-c1.my-sli-play-f {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: white;
  border-radius: 10px;
  padding: 3px 15px;
}
.biling-m-c1.my-sli-play-f b {
  display: flex;
  font-size: 11px;
  margin-left: 10px;
  width: 100px;
  display: flex;
  justify-content: flex-end;
}
.biling-m-c1.my-sli-play-f .ant-slider .ant-slider-track {
  background-color: #60789e;
}
.form-pf {

  /* background-color: rgb(95 120 158); */
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  position: relative;
  /* border-radius: 25px; */
  margin-top: 20px;
  border-radius: 15px;
}
.form-pf .label-pf {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: -moz-fit-content;
  height: fit-content;
}
.form-pf .label-pf:has(input:focus) .title-pf {
  top: 0;
  left: 0;
  color: #bedbff;
}
.form-pf .label-pf:has(.ant-input:focus) .title-pf {
  top: 0;
  left: 0;
  color: #bedbff;
}
.form-pf .label-pf:has(select:focus) .title-pf {
  top: 0;
  left: 0;
  color: #bedbff;
}

.form-pf .label-pf .title-pf {
  padding: 0 10px;
  transition: all 300ms;
  font-size: 12px;
  color: white;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
  top: 14px;
  position: relative;
  left: 15px;
  background: #3a3b3c;
  z-index: 15;
  border-radius: 10px;
}
.form-pf .ant-select .ant-select-arrow{
  color: white;
}
.form-pf .input-field-pf,
.form-pf   .ant-select .ant-select-selector ,
.form-pf .ant-input,
.form-pf .ant-input[disabled] {
  width: auto;
  height: 40px;
  text-indent: 15px;
  /* border-radius: 15px; */
  outline: none;
  background-color: black ;
  border: 1px solid black;
  transition: all 0.3s;
  caret-color: #bedbff;
  color: white;
  border-radius: 10px;
}

.form-pf .input-field-pf:hover,
.form-pf .ant-input:hover {
  border-color: #60789e;
}

.form-pf .input-field-pf:focus,
.form-pf .ant-input:focus {
  border-color: transparent;
  background-color: #404040;
  color: white;
  box-shadow: none;
}
.ant-slider .ant-slider-rail {
  background-color: #a4a4a4;
}
.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #bedbff;
}
.ant-slider .ant-slider-track {
  background-color: #bedbff;
}
.slider-bx-c {
  display: flex;
  align-items: flex-start;
}
.ant-input-number .ant-input-number-input {
  background-color: #0c0f14;
}
.ant-input-number .ant-input-number-input {
  color: white;
}

.form-pf .split-pf {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
}
.form-pf .split-pf label {
  width: 130px;
}
.form-pf .checkout-btn-pf {
  margin-top: 20px;
  padding: 20px 0;
  border-radius: 25px;
  font-weight: 700;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  fill: #fff;
  color: #fff;
  border: 2px solid transparent;
  background: #bedbff;
  transition: all 200ms;
}
.form-pf .checkout-btn-pf:active {
  scale: 0.95;
}

.form-pf .checkout-btn-pf:hover {
  color: #bedbff;
  border: 2px solid #bedbff;
  background: transparent;
}

.selected-crd.hover-card-b {
  border: 1px solid gold;
  box-shadow: 4px 5px gold;
  color: black;
  background-image: linear-gradient(to right, gold, yellow);
}

.slider-image-si img {
  height: 300px;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
}

.simu-card-t-i {
  margin-bottom: 10px;
  font-size: 18px;
}
.simu-card-t-i svg {
  font-size: 30px;
  padding: 3px;
  margin-right: 10px;
}
.simu-main-cards-c {
  padding: 10px 0px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.simu-main-cards-c .hover-card-b {
  height: 100%;
  align-items: flex-start;
  color: white;
  text-transform: none;
}
.simu-main-cards-c .ant-row {
  max-width: 680px;
  width: 100%;
}
.space-b-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ling-text-header {
  text-decoration: none;
  color: white;
  margin: 0px 5px;
  font-weight: 500;
}
.ling-text-header:hover {
  transition: 0.5s;
  color: #bedbff;
}

.live-e-card {
  background: transparent;
  box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
  position: relative;

  height: 300px;
  border-radius: 10px;
  overflow: hidden;
}
.live-e-card .ant-typography {
  font-size: 18px;
  color: white;
  margin: 0px 5px;
}
.learn-more-card-c {
  margin-top: 20px;
}
.learn-more-card-c .hover-card-b {
  color: rgb(188, 234, 252);
  text-transform: capitalize;
  background-color: transparent;
  border: 1px solid skyblue;
  box-shadow: 3px 4px skyblue;
}
.live-wave {
  position: absolute;
  width: 540px;
  height: 700px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -50%;
  margin-top: -70%;
}
.green-wave.live-wave {
  background: linear-gradient(744deg, #1aac00, #20a200 60%, #00eb76);
}
.orange-wave.live-wave {
  background: linear-gradient(744deg, #ac9500, orange 60%, #8d7b08);
}

.yellow-wave.live-wave {
  background: linear-gradient(744deg, #717171, yellow 60%, rgb(131, 114, 2));
}

.live-icon {
  font-size: 50px;
  margin-bottom: 10px;
}

.live-infotop {
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 5.6em;
  left: 0;
  right: 0;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.live-name {
  font-size: 14px;
  font-weight: 100;
  position: relative;
  top: 1em;
  bottom: 0;
  text-transform: lowercase;
}

.live-wave:nth-child(2),
.live-wave:nth-child(3) {
  top: 210px;
}

.live-playing .live-wave {
  border-radius: 40%;
  animation: wave 3000ms infinite linear;
}

.live-wave {
  border-radius: 40%;
  animation: wave 55s infinite linear;
}

.live-playing .live-wave:nth-child(2) {
  animation-duration: 4000ms;
}

.live-wave:nth-child(2) {
  animation-duration: 50s;
}

.live-playing .live-wave:nth-child(3) {
  animation-duration: 5000ms;
}

.live-wave:nth-child(3) {
  animation-duration: 45s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.bottom-icnx-s {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.time-counter-r {
  display: flex;
  justify-content: center;
}
.time-counter-r span {
  /* background-color: rgba(0, 0, 0, 0.512); */
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.time-counter-r span b {
  padding: 8px 0px;
  background-color: rgb(51, 51, 51);
  color: white;
  margin: 0px 3px;
  min-width: 30px !important;
  display: block;
  text-align: center;
}

.c3-card {
  position: relative;
  border-radius: 10px;
  height: 300px;
  background: linear-gradient(-45deg, #bedbff 0%, #4b6c94 100%);
  overflow: hidden;
  border: 1px solid black;
  box-shadow: black 0px 40px 30px -25px;
  transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.c3-card:hover {
  transform: scale(1.02);
  box-shadow: rgba(141, 177, 205, 0.618) 0px 30px 30px -25px;
  border-radius: 10px;
}

.c3-logo {
  width: 50px;
  padding: 1rem;
}

.c3-logo .c3-svg {
  fill: white;
}

.c3-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-weight: bold;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
}
.c3-title span {
  font-size: 13px;
}
.c3-box {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: rgba(66, 66, 66, 0.349) 5px 0px 10px 0px inset;
  transition: all 0.5s ease-in-out;
  display: grid;
  place-content: center;
  padding: 2rem 2rem 3rem 2rem;
  border: 1px solid black;
}

.c3-box .c3-content {
  position: relative;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.5s ease-in-out 0.3s;
}

.c3-box .c3-content .c3-box-title {
  display: block;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.content-card-mainc {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  height: 100%;
}

.c3-box .c3-content .c3-box-text {
  color: rgba(255, 255, 255, 0.738);
  font-size: 0.8rem;
}

.c3-box:hover {
  right: 0;
  top: 0;
  border-radius: 0;
  border: 1px solid transparent;
  box-shadow: rgba(66, 66, 66, 0) 5px 0px 10px 0px inset;
}

.c3-box:hover .c3-content {
  transform: translate(0, 0);
  opacity: 1;
}

.c3-box1 {
  top: -85%;
  right: -40%;
  background: rgb(0 44 98);
  border-radius: 200px;
}

.c3-box2 {
  top: -70%;
  right: -45%;
  background: rgb(35 78 137);
  border-radius: 200px;
}

.c3-box3 {
  top: -62%;
  right: -30%;
  background: rgb(78 119 182);
  border-radius: 200px;
}

.c3button {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica, "sans-serif";
  transition: all 0.2s ease-in;
  padding: 10px 20px;
  border-radius: 100px;
  background: linear-gradient(-45deg, white 0%, white 100%);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 0px 10px;
}
.active-live-c.c3button {
  background: linear-gradient(-45deg, #bedbff 0%, #4b6c94 100%);
}
.c3button:hover {
  cursor: pointer;
}

.c3button > svg {
  width: 34px;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.c3button:hover svg {
  transform: translateX(5px);
}

.c3button:active {
  transform: scale(0.95);
}

.s3-tabs-c {
  display: flex;
  justify-content: center;
  /* margin-bottom: 20px; */
  width: 100%;
}
.flex-end-top-c {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.flex-end-top-c .hover-card-b svg {
  margin-left: 5px;
  margin-right: 5px;
}
.flex-end-top-c .hover-card-b:hover svg {
  margin-left: 10px;
  margin-right: 0px;
  transition: 0.5s;
}
.together-fields-c {
  display: flex;
}
.date-title-c {
  margin-bottom: 60px;
}
.date-title-c h3 {
  color: #fbc97e;
  background-color: rgb(255 255 255 / 10%);
  display: inline;
  padding: 10px;
  position: relative;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
}
.date-title-c h3 svg {
  margin-right: 10px;
  color: #bedbff;
  /* position: absolute; */
  /* left: 10px; */
}
.date-title-c .ant-row {
  margin-top: 20px;
}
.calender-con {
  background-color: black;
}
.rbc-time-content {
  max-height: 80vh;
  overflow-y: auto;
}
.rbc-toolbar {
  margin: 20px 0px;
}
.calender-con .rbc-btn-group button {
  background-color: #1a202c !important;
  margin: 0px 5px !important;
  font-weight: 600;
  border-radius: 25px !important;
  border: 1px solid black;
  background: linear-gradient(-45deg, #0072b8 0%, #130fd7 100%);
  color: white !important;
}
.calender-con .rbc-btn-group button:hover {
  background: linear-gradient(-45deg, #130fd7 100%, #0072b8 0%);
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background: linear-gradient(-45deg, #090909, #3a3a3a);
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active:hover {
  background: linear-gradient(-45deg, #000000, #020202);
  border-color: transparent !important;
}
.calender-con .rbc-timeslot-group {
  border-color: #1a202c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calender-con .rbc-time-view {
  border-color: #1a202c;
}
.calender-con .rbc-time-header.rbc-overflowing {
  border-color: #1a202c !important;
}
.calender-con .rbc-today {
  background-color: transparent;
}
.calender-con .rbc-event,
.calender-con .rbc-day-slot .rbc-background-event {
  background: linear-gradient(to right, rgb(26, 32, 44), rgb(26, 32, 44));
  padding: 10px;
  border-radius: 10px;
  margin: 0px 10px;
  border: 2px solid black !important;
  max-width: 400px;
}
.calender-con .rbc-day-slot .rbc-event,
.calender-con .rbc-day-slot .rbc-background-event {
  border: none;
}

.card-inner-cal strong {
  font-size: 14px;
  font-weight: 500;
  color: #2ffff3;
}
.card-inner-cal strong img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 10px;
}
.card-inner-cal p {
  margin: 15px 0px 0px 15px;
  font-size: 12px;
}
.calender-con .rbc-day-slot .rbc-event-label {
  color: #8fadc0;
  margin-top: 5px;
  display: none;
}
.card-inner-cal button {
  position: absolute;
  top: 10px;
  left: 150px;
  background: linear-gradient(to right, rgb(26, 26, 26), rgb(0, 0, 0));
  padding: 5px;
  border-radius: 10px;
  font-size: 12px;
  color: white;
  border-color: transparent;
}
.card-inner-cal button:hover {
  cursor: pointer;
  background: linear-gradient(to right, rgb(72, 72, 72), rgb(0, 0, 0));
}
.rbc-time-header-content .rbc-event {
  margin-top: 10px !important;
  position: relative;
}
.card-inner-time {
  position: absolute;
  bottom: 10px;
  right: 10px;

  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  border: 1px solid rgb(1, 95, 129);
  color: rgb(84, 209, 255);
}
.card-inner-img {
  position: absolute;
  bottom: 10px;
  right: 45px;
  width: 24px;
}
.card-inner-live {
  position: absolute;
  top: 10px;
  right: 0px;
}
.sumilation-main-tbs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sumilation-main-tb2 .c3button {
  width: 140px;
}
.blue-gr-btn {
  background: linear-gradient(-45deg, #0072b8 0%, #130fd7 100%);
  color: white !important;
}
.blue-gr-btn:hover {
  background: linear-gradient(#130fd7, #0072b8);
  transition: all ease-in-out 0.4s;
}
.switch-v-c {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px 5px 0px;
}
.switch-v-c b {
  margin: 0px 5px;
  padding: 5px;
  font-size: 20px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: black;
  border-radius: 5px;
  cursor: pointer;
}
.switch-v-c b.active-s-i {
  background: linear-gradient(#130fd7, #0072b8);
}
/* .css-oa3kic-ProgramBox .css-4jp2nc-ProgramContent {
  background: linear-gradient(to right, #bedbff, #fec598);
}
.css-4jp2nc-ProgramContent:hover .css-yu4rga-ProgramText ,.css-4jp2nc-ProgramContent:hover .css-105khot-ProgramTitle {
  color: white;
}
.css-4jp2nc-ProgramContent .css-yu4rga-ProgramText ,.css-4jp2nc-ProgramContent .css-105khot-ProgramTitle {
  color: black;
} */

.user-q-chat > div p {
  margin-bottom: 0px;
  color: black;
  font-weight: 500;
}
.user-r-chat {
  width: 100%;
  display: flex;
  padding: 10px;
  padding-right: 15%;
  color: white;
}
.user-r-chat > p {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 10px;
  border-radius: 10px;
  border-top-left-radius: 0px;
}
.user-r-chat .display-goal-st-s1 {
  padding: 0px;
}
.user-r-chat .display-goal-st-s1 img {
  border-radius: 5px !important;
  object-fit: contain;
  width: auto;
}
.user-r-chat .display-goal-st-s1 p {
  margin-bottom: 0px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
}
.user-r-chat .video-thumbnail-imgg2s {
  height: auto;
}
.chat-bx-mnd {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 50px;
}
.chat-main-d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.video-watch-c {
  /* height: 90vh; */
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-between;

  /* align-items: center; */
}
.flex-end p {
  margin-bottom: 0px;
}
.watch-logo-c {
  position: absolute;
  padding-left: 10px;
  padding-top: 10px;
}

.video-watch-c .video-container.video-container-round {
  padding: 0px 15px;
  /* border-radius: 15px; */
  /* background-color: white; */
  /* background: linear-gradient(to right, #1d2b64, #f8cdda); */
  /* background: #3a3b3c !important; */
  border: 2px solid #3a3b3c;
  border-radius: 5px;
}
.video-watch-c .video-container.video-container-round video {
  border-radius: 10px;
  max-height: 600px;
}
.hover-card-b {
  color:white;
  text-transform: capitalize;
  /* background-color: transparent; */
  border: 1px solid white;
  box-shadow: 3px 4px white;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.45s;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 10px;

}
.hover-card-b:hover {
  box-shadow: none;
  cursor: pointer;
}
.flex-end-bvh p {
  margin-right: 10px;
}
.flex-end-bvh {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex-end-bvh p {
  margin-bottom: 0px;
}
.public-video-card {
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  background-color: #3c3c3c;
  padding: 5px;
  border-radius: 5px;
}
.public-video-card img {
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.public-page-c {
  height: 100vh;
  overflow-y: scroll;
}
.bottom-right-c {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.public-top-c {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.public-top-c b {
  padding: 5px 10px;
  color: white;
  background-color: #1d29389d;

  text-align: center;
  border-radius: 5px;
  font-weight: 600;
}
.public-top-c2 {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 3px;
  color: white;

  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  
}
.btm-c-mem-crd{
  display: flex;
  justify-content: space-between;

}
.btm-c-mem-crd span{
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  padding: 5px;
}
.right-arrow-public {
  position: relative;
  z-index: 10;
  color: black;
  background-image: linear-gradient(to right, black, grey);
color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-arrow-public:hover {
  background-image: linear-gradient(to right, black, black);

  color: white;
  cursor: pointer;
  transition: 0.4s;
}
.right-arrow-public svg {
  margin-left: 2px;
}
.right-arrow-public:hover svg{
  transform: scale(1.1);
  transition: 0.4s;

}
.tab-r-vc {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  overflow-x: auto;
  overflow-y: clip;
}

.error-s-svg {
  color: red;
  font-size: 25px;
}
.ins-height-sc {
  height: 100%;
  padding-bottom: 60px;
  overflow-y: auto;
  width: 100%;
}

.flex {
  display: flex;
}

.flex-shrink-0 {
  flex-shrink: 0;
}
