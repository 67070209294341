
.account.lbl-small {
  font-size: 12px;
}

.account.float-right {
  float: right;
  color: white;
}
.user-name-acc-c .col-12{
 margin-bottom: 15px;
}
.user-name-acc-c .col-12:last-of-type{
  margin-bottom: 0px;
 }
 .user-name-acc-c {
  max-width: 500px;
 }
 .bx-form-brand{
  display: flex;
  flex-direction: column;
  align-items: center;
 }
 .compute-tab-c{
  width: 100%;
 }
 .add-new-pro-c{
  margin: 10px 0px;
 }
 .ant-alert-info{
  background-color: transparent;
  color: white;
  border-color: #ffffff14;
 }
 .ant-alert-info .ant-alert-message{
  color: white;

 }
 .ant-alert-info svg{
  color: white;
 }
.black-table button.ant-table-row-expand-icon{
  background-color: #ffffff14 !important;
 }
 /* .tags-date-area label.label-pf{
  display: flex;
  flex-direction: row;
 } */
 .shiw-api-k{
  /* margin: 20px 10px; */
  display: flex;
  justify-content: flex-end;
 }
 .shiw-api-k .ant-tag{
  cursor: pointer;
 }