.cards-c-step1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-img-s1 {
  width: 100%;

  border-radius: 5px;
}
.tele-cards .my-lbutton {
  padding: 3px 7px;
}
.tele-card.tele-blue.heigth-tele-card.full-w-tele {
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
}
.card-img-s1 {
  width: 250px;
  margin-right: 10px;
  height: 100%;
  object-fit: contain;
}
.tele-m-c-f1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.tele-cards.tele-more-space-c {
  padding: 5px 60px;
}
.tele-cards.tele-more-space-c.gold-card-s .tele-card {
  background-image: linear-gradient(145deg, gold, brown);
}
.my-lbutton .arrow-right-c {
  font-size: 12px !important;
  position: relative;
  right: 0px;
}
.my-lbutton:hover .arrow-right-c {
  position: relative;
  right: -5px;
  transition: 0.3s;
}
.my-lbutton.red-lb {
  background-image: linear-gradient(to right, #c60000, #9d0d00);
  color: white;
}
.countdown-css-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.countdown-css-c span {
  margin-bottom: 10px;
}
.io0mrg-icn {
  color: white;
  font-size: 20px;
  margin-top: 7px;
  margin-right: 5px;
}
.bnt-cssaf .ant-btn {
  color: white;
}
.icon-vd-mrc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: black;
  color: yellow;
  font-size: 16px;
}
.icon-eye-topc0s {
  position: absolute;
  left: 10px;
  z-index: 20;
  top: -5px;
}
.public-top-c .icon-eye-topc0s {
  background-color: black !important;
  color: white !important;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.public-top-c .icon-eye-topc0s:hover {
  color: black !important;
  background-color: yellow !important;
  cursor: pointer;

  transition: 0.4s;
}
.public-top-c .icon-eye-topc0s svg {
  position: absolute;
  font-size: 16px !important;
}
.public-video-card:hover .show-hover {
  display: inline;
}
.video-contc {
  color: white;
  display: flex;
  flex-direction: column;
  background-color: #1d1d1d;
  padding: 15px;
  border-radius: 5px;
  align-items: center;
  border: 1px solid rgb(205 205 205 / 37%);
}
.feeed-t-c-top {
  padding: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #1d1d1d !important;
  /* border-left: 1px solid gold;
  border-right: 1px solid gold; */
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 5px;
}
.feeed-t-div-cs {
  padding-bottom: 10px;
}
.empty-desc-c {
  color: rgb(157, 157, 157);
}
.play-btn-icnsvc {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.icon-circle-br-t {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: black;
  color: yellow;
  cursor: pointer;
}
.icon-circle-br-t svg {
  font-size: 12px;
}
.icon-circle-br-t:hover {
  color: black;
  background-color: yellow;
  transition: all 0.5s;
}
.video-contc-t-i {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.content-cvc {
  padding: 20px;
  width: 100%;
}
.video-contc-t-i .number-cir-cvc2 {
  color: yellow;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: black;
  font-size: 17px;
}
.video-contc-t-i .number-cir-cvc {
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: black;
  font-size: 12px;
}
.transparent-textarea {
  background-color: transparent;
  border-color: transparent;
  width: 100% !important;
  color: white;
  border-radius: 5px;
}
.transparent-textarea:hover,
.transparent-textarea:focus {
  background-color: transparent;
  border-color: rgba(255, 255, 0, 0.521);
  color: white;
}
.text-c-dds {
  color: white;
  text-align: center;
}
.flex-t-c-dc {
  display: flex;
  align-items: center;
}
.flex-t-c-dc b {
  margin-left: 5px;
}
.yellow-tag {
  background-color: yellow;
  color: black;
  border-color: yellow;
  font-weight: bold;
  cursor: pointer;
}
.black-tag {
  background-color: black;
  color: white;
  border-color: black;
  font-weight: bold;
  cursor: pointer;
}
.drop-select-flex {
  display: flex;
  align-items: center;
}
.text-drop-s {
  margin-left: 5px;
}
.black-select .ant-select-selector {
  background-color: #3a3b3c !important;
  color: white;
  border-color: transparent !important;
}
.black-select .ant-select-arrow {
  color: white !important;
}
.video-contc:hover .number-cir-cvc {
  display: none;
}
.video-contc:hover .number-cir-cvc232 {
  display: inline;
}
.number-cir-cvc232 {
  display: none;
}
.feeed-t-c-top.yellow-card {
  background-color: yellow !important;
}
.green-theme-f {
  background-image: linear-gradient(to right, orange, rgb(254, 175, 29));
  color: black;
  border-color: transparent;
  cursor: pointer;
}

.green-theme-f .svg-r {
  left: 5px;
  font-size: 12px;
  position: relative;
}
.green-theme-f:hover .svg-r {
 left: 10px;
  transition: 0.4s;
}
.title-feed-pop{
  color: white;
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
}
