.my-llanding-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 100vh;
  padding: 20px;
  color: white;
  overflow-y: auto;
  /* background-image: url("../../images//background-land.png");
  background-size: cover; 
  background-position: center; */
  width: 100%;
}
.my-llanding-page .my-llanding-page2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 760px;
}

.my-lheading {
  font-size: 4rem;
  text-align: center;
  margin-top: 100px;
  color: white;
}

.my-lparagraph {
  margin-top: 20px;
  text-align: center;
  max-width: 500px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  color: black;
}

.my-lvideo-container {
  margin-top: 20px;
}
.image-main-card-lnd {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0px;
}
.mian-cntr-img {
  max-height: 250px;
  object-fit: contain;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}
.snd-img-lnd {
  position: absolute;
}
.search-container-ds {
  /* position: absolute; */
  width: 100%;
  border-radius: 50px;

  /* padding: 5px; */

  display: flex;
  align-items: center;
  justify-content: center;
}
.grey-input-center {
  border-color: transparent;
  border-radius: 50px;
  color: black;
  width: 250px;
}
.grey-input-center:hover {
  cursor: text;
  border-color: transparent;
}
.grey-input-center:focus {
  border-color: transparent !important;
  box-shadow: none;
  background-color: #a3a3a3;
}

.search-container-ds .ant-input {
  left: 0;
  right: 0;
  position: absolute;
  width: 300px;
  border-radius: 15px;
}

.search-container-ds::after,
.search-container-ds::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
}

.search-container-ds::before {
  top: -1px;
  left: -1px;
  background: linear-gradient(
    0deg,
    rgb(218, 232, 247) 0%,
    rgb(255, 255, 255) 100%
  );
  z-index: -1;
}

.search-container-ds::after {
  bottom: -1px;
  right: -1px;
  background: linear-gradient(
    0deg,
    rgb(163, 206, 255) 0%,
    rgb(211, 232, 255) 100%
  );
  box-shadow: rgba(79, 156, 232, 0.7019607843) 3px 3px 5px 0px,
    rgba(79, 156, 232, 0.7019607843) 5px 5px 20px 0px;
  z-index: -2;
}

.input-ds {
  padding: 10px;
  width: 100%;
  background: linear-gradient(
    135deg,
    rgb(218, 232, 247) 0%,
    rgb(214, 229, 247) 100%
  );
  border: none;
  color: black;
  font-size: 20px;
  border-radius: 50px;
}

.input-ds:focus {
  outline: none;
  background: linear-gradient(
    135deg,
    rgb(239, 247, 255) 0%,
    rgb(214, 229, 247) 100%
  );
}

.search__icon {
  width: 50px;
  aspect-ratio: 1;
  border-left: 2px solid white;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  padding-left: 12px;
  margin-right: 10px;
}

.search__icon:hover {
  border-left: 3px solid white;
}

.search__icon path {
  fill: white;
}
.img-1-ch {
  position: absolute;
  height: 350px;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.img-13-ch {
  position: absolute;
  height: 200px;
  left: 0;
  bottom: 0;
  z-index: 20;
}
.popup-center-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.popup-center-text .my-lbuttons {
  margin-top: 50px;
}
.popup-center-text .my-lheading {
  margin-top: -50px;
}
.my-lvideo {
  width: 700px;
  max-width: 800px;
  min-height: 300px;
}
.landing-logo-no-u {
  width: 100%;
  top: 0;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
}
.landing-logo-no-10 {
  /* background: linear-gradient(-45deg, #b8dff0, #b8dff0); */
  padding: 10px 10px 10px 30px;
  border-radius: 5px;
  position: relative;
  z-index: 30;
}
.my-lbuttons {
  margin-top: 260px;
  width: 350px;
  display: flex;
  justify-content: space-around;
}
.text-sixe0card {
  font-size: 16px;
  font-weight: 500;
}
.card-vd-top-m h5 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}
.my-lbutton-10 {
  color: white;
  background-color: transparent;
}
.landing-logo-no-u a {
  text-decoration: none;
}
.my-lbutton-10:hover {
  color: #edfff7;
}

.my-lbutton {
  padding: 10px 20px;
  font-size: 1rem;
  background-image: linear-gradient(to right, gold, yellow);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 500;
}

.my-lbutton:last-child {
  margin-right: 0;
}

.sec-332 {
  padding: 80px 40px;
  border-radius: 10px;
  margin: 60px 0px 20px 0px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  display: flex;
  justify-content: center;
}
.overlay-vd {
  position: relative; /* Changed to relative */
  z-index: 100; /* Ensure the overlay is above the video */
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.video-container-vd video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto; /* Adjusted to maintain aspect ratio */
  object-fit: cover;
}

.ui-uiverse {
  --duration: 7s;
  --easing: linear;
  --c-color-1: rgba(255, 163, 26, 0.7);
  --c-color-2: #1a23ff;
  --c-color-3: #e21bda;
  --c-color-4: rgba(255, 232, 26, 0.7);
  --c-shadow: rgba(255, 223, 87, 0.5);
  --c-shadow-inset-top: rgba(255, 223, 52, 0.9);
  --c-shadow-inset-bottom: rgba(255, 250, 215, 0.8);
  --c-radial-inner: #ffd215;
  --c-radial-outer: #fff172;
  --c-color: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  outline: none;
  position: relative;
  cursor: pointer;
  border: none;
  display: table;
  border-radius: 24px;
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.5;
  color: black;
  background: radial-gradient(
    circle,
    var(--c-radial-inner),
    var(--c-radial-outer) 80%
  );
  box-shadow: 0 0 14px var(--c-shadow);
}

.ui-uiverse:before {
  content: "";
  pointer-events: none;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  box-shadow: inset 0 3px 12px var(--c-shadow-inset-top),
    inset 0 -3px 4px var(--c-shadow-inset-bottom);
}

.ui-uiverse .ui-wrapper {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  border-radius: 24px;
  min-width: 132px;
  padding: 12px 0;
}
.top-20-modal {
  top: 20px;
}

.ui-uiverse .ui-wrapper span {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.ui-uiverse .ui-wrapper .ui-circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 140px;
  height: 50px;
  border-radius: 50%;
  filter: blur(var(--blur, 8px));
  background: var(--background, transparent);
  transform: translate(var(--x, 0), var(--y, 0)) translateZ(0);
  animation: var(--animation, none) var(--duration) var(--easing) infinite;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-1,
.uiverse .wrapper .ui-circle.circle-9,
.uiverse .wrapper .ui-circle.circle-10 {
  --background: var(--c-color-4);
}

.ui-uiverse .ui-wrapper .ui-circle.ui-circle-3,
.ui-uiverse .ui-wrapper .ui-circle.circle-4 {
  --background: var(--c-color-2);
  --blur: 14px;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-5,
.ui-uiverse .ui-wrapper .ui-circle.circle-6 {
  --background: var(--c-color-3);
  --blur: 16px;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-2,
.ui-uiverse .ui-wrapper .ui-circle.circle-7,
.ui-uiverse .ui-wrapper .ui-circle.circle-8,
.ui-uiverse .ui-wrapper .ui-circle.circle-11,
.ui-uiverse .ui-wrapper .ui-circle.circle-12 {
  --background: var(--c-color-1);
  --blur: 12px;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-1 {
  --x: 0;
  --y: -40px;
  --animation: circle-1;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-2 {
  --x: 92px;
  --y: 8px;
  --animation: circle-2;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-3 {
  --x: -12px;
  --y: -12px;
  --animation: circle-3;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-4 {
  --x: 80px;
  --y: -12px;
  --animation: circle-4;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-5 {
  --x: 12px;
  --y: -4px;
  --animation: circle-5;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-6 {
  --x: 56px;
  --y: 16px;
  --animation: circle-6;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-7 {
  --x: 8px;
  --y: 28px;
  --animation: circle-7;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-8 {
  --x: 28px;
  --y: -4px;
  --animation: circle-8;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-9 {
  --x: 20px;
  --y: -12px;
  --animation: circle-9;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-10 {
  --x: 64px;
  --y: 16px;
  --animation: circle-10;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-11 {
  --x: 4px;
  --y: 4px;
  --animation: circle-11;
}

.ui-uiverse .ui-wrapper .ui-circle.circle-12 {
  --blur: 14px;
  --x: 52px;
  --y: 4px;
  --animation: circle-12;
}

@keyframes circle-1 {
  33% {
    transform: translate(0px, 16px) translateZ(0);
  }

  66% {
    transform: translate(12px, 64px) translateZ(0);
  }
}

@keyframes circle-2 {
  33% {
    transform: translate(80px, -10px) translateZ(0);
  }

  66% {
    transform: translate(72px, -48px) translateZ(0);
  }
}

@keyframes circle-3 {
  33% {
    transform: translate(20px, 12px) translateZ(0);
  }

  66% {
    transform: translate(12px, 4px) translateZ(0);
  }
}

@keyframes circle-4 {
  33% {
    transform: translate(76px, -12px) translateZ(0);
  }

  66% {
    transform: translate(112px, -8px) translateZ(0);
  }
}

@keyframes circle-5 {
  33% {
    transform: translate(84px, 28px) translateZ(0);
  }

  66% {
    transform: translate(40px, -32px) translateZ(0);
  }
}

@keyframes circle-6 {
  33% {
    transform: translate(28px, -16px) translateZ(0);
  }

  66% {
    transform: translate(76px, -56px) translateZ(0);
  }
}

@keyframes circle-7 {
  33% {
    transform: translate(8px, 28px) translateZ(0);
  }

  66% {
    transform: translate(20px, -60px) translateZ(0);
  }
}

@keyframes circle-8 {
  33% {
    transform: translate(32px, -4px) translateZ(0);
  }

  66% {
    transform: translate(56px, -20px) translateZ(0);
  }
}

@keyframes circle-9 {
  33% {
    transform: translate(20px, -12px) translateZ(0);
  }

  66% {
    transform: translate(80px, -8px) translateZ(0);
  }
}

@keyframes circle-10 {
  33% {
    transform: translate(68px, 20px) translateZ(0);
  }

  66% {
    transform: translate(100px, 28px) translateZ(0);
  }
}

@keyframes circle-11 {
  33% {
    transform: translate(4px, 4px) translateZ(0);
  }

  66% {
    transform: translate(68px, 20px) translateZ(0);
  }
}

@keyframes circle-12 {
  33% {
    transform: translate(56px, 0px) translateZ(0);
  }

  66% {
    transform: translate(60px, -32px) translateZ(0);
  }
}

.bottom-right-pop {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}
.card-logo-top-l {
  /* background: linear-gradient(-45deg,#b8dff0,#b8dff0); */
  margin-top: 10px;

  border-radius: 0px;
}
.card-vd-top-m {
  padding: 5px;
  background: linear-gradient(-45deg, #b8dff0, #b8dff0); /* color: black; */
  margin: 5px;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.card-vd-top-m img {
  width: 100px;
  border-radius: 5px;
}
.video-container.full-width > div {
  max-width: 100%;
}
.card-vd-top-m.active-video-landing {
  background-image: linear-gradient(to right, #004dc6, #06b4ff);
}
.landing-info-t {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: space-between; */
  position: relative;
  height: 100%;
  padding: 20px;
  padding-top: 80px;
  width: 86%;
  margin-left: 7%;
  /* border: 1px solid rgb(255, 255, 255); */
  border-radius: 10px;
  /* background-color: rgba(255, 255, 255, 0.131);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px; */
}

.card-vd-top-m.active-video-landing .ant-typography {
  color: white;
  font-weight: 500;
}
.bottom-left-popup .my-lbutton {
  position: relative;
  z-index: 30;
  padding: 3px 7px;
}
.landing-logo-no-10 .my-lbutton {
  padding: 5px 10px;
}

.loader-video-img {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.loader-video-img img {
  width: 100px;
  height: 100px;

  animation: blinkImg 1s infinite alternate; /* Using the 'blink' animation */
}

/* Keyframes for the blink animation */
@keyframes blinkImg {
  0% {
    opacity: 1; /* Fully visible */
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);

    opacity: 0; /* Fully transparent */
  }
}
.testing-animation-cads .card-vd-top-m {
  color: black;
  padding: 15px;
  max-width: 350px;
}

.testing-animation-cads1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.show-mobile {
  display: none;
}

/* Slow motion button */
#slowmo-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  border: 0;
  font-size: 1rem;
  background: #fedc2a;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.275s;
}

#slowmo-toggle span {
  display: block;
  font-weight: normal;
}

#slowmo-toggle:hover,
#slowmo-toggle:focus {
  background: white;
  color: #fedc2a;
}

/* When slow motion is activated */
.is-slowmo + .custom-device .open-main-nav .menu-icon,
.is-slowmo + .custom-device .open-main-nav .menu-icon:before,
.is-slowmo + .custom-device .open-main-nav .menu-icon:after,
.is-slowmo + .custom-device .custom-main-nav,
.is-slowmo + .custom-device .custom-main-nav::before,
.is-slowmo + .custom-device .custom-main-nav a {
  transition-duration: 3s;
}

.is-slowmo + .custom-device .custom-main-nav li:nth-child(1) a {
  transition-delay: 1750ms;
}

.is-slowmo + .custom-device .custom-main-nav li:nth-child(2) a {
  transition-delay: 2250ms;
}

.is-slowmo + .custom-device .custom-main-nav li:nth-child(3) a {
  transition-delay: 2750ms;
}

.is-slowmo + .custom-device .custom-main-nav li:nth-child(4) a {
  transition-delay: 3250ms;
}

.is-slowmo + .custom-device .custom-main-nav li:nth-child(5) a {
  transition-delay: 3750ms;
}

/* Main menu positioning */
.custom-main-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  /* background: #FFF; */
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all 0.375s;
}

.custom-main-nav.is-open {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}

/* Yellow band effect */
.custom-main-nav::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -15px;
  background-image: linear-gradient(to right, #404040, #161616);

  transform-origin: 0 0;
  transform: skew(-14deg) translateX(-120%);
  transition: all 0.275s 0.1s;
}

.custom-main-nav.is-open::before {
  transform: skew(-14deg) translateX(0);
}

/* Skewing effect on menu links */
.custom-main-nav ul {
  display: inline-flex;
  flex-direction: column;
  height: 93%; /* Should be 100%, but we have a notice message :D */
  align-items: flex-end;
  justify-content: center;
  transform: translateX(-18%) skew(-16deg);
}

.custom-main-nav li {
  display: block;
  margin: 0.5rem 0;
  text-align: right;
  transform: skew(16deg);
}

/* Apparition effect on links */
.custom-main-nav a {
  opacity: 0;
  transform: translateY(-10px);
}

.custom-main-nav.is-open a {
  opacity: 1;
  transform: translateY(0);
}

/* Decoration */
.custom-main-nav ul,
.custom-main-nav li {
  list-style: none;
  padding: 0;
}

.custom-main-nav a {
  display: block;
  padding: 12px 0;
  color: white;
  font-size: 1.4em;
  text-decoration: none;
  font-weight: bold;
}

/* Burger Style */
.open-main-nav {
  position: absolute;
  top: 15px;
  padding-top: 20px;
  right: 15px;
  z-index: 1000;
  background: none;
  border: 0;
  cursor: pointer;
}

.open-main-nav:focus {
  outline: none;
}

.menu-icon {
  position: relative;
  display: block;
  width: 28px;
  height: 4px;
  margin: 0 auto;
  background: white;
  transform: skew(5deg);
  transition: all 0.275s;
}
.menu-text {
  color: white;
}
.menu-icon:after,
.menu-icon:before {
  content: "";
  display: block;
  height: 100%;
  background: white;
  transition: all 0.275s;
}

.menu-icon:after {
  transform: translateY(-12px) translateX(-2px) skew(-20deg);
}

.menu-icon:before {
  transform: translateY(-16px) skew(-10deg);
}

/* Toggle State part */
.is-open .menu-icon {
  transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.is-open .menu-icon:before {
  transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .menu-icon:after {
  transform: translateY(-12px) translateX(10px) skew(-20deg);
  opacity: 0;
}

/* Notice */
.custom-notice {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  padding: 20px;
  background: #f2f2f2;
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  z-index: 100;
  text-align: center;
}

.custom-notice strong {
  font-weight: 700;
}

.custom-notice a {
  padding: 2px 3px;
  background: #fedc2a;
  text-decoration: none;
}
.video-container.full-height > div {
  width: 100% !important;
  margin: 0 auto;
  height: 100% !important;
  /* max-height: 300px; */
  border-radius: 10px;
  padding: 20px 20px 2px 20px;
}
.video-container.full-height video {
  border-radius: 10px !important;
}
.testing-animation-cads {
  position: absolute;
  bottom: 60px; /* Adjust the distance from the bottom */
  left: 10px; /* Adjust the distance from the left */
  z-index: 10;
}
img.image-background-c-la {
  width: 100vw !important;
  height: 100vh !important;
  object-fit: cover !important;
}
.desc-p-crdjjkl {
  color: white !important;
}

.blurry-background {
  /* Set the background image */

  /* Full height of the viewport */
  height: 100vh;
  /* background-image: url("../../images//FznC6ff.jpg"); */
  /* Center and scale the background image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  background-size: 100% 100%;
  animation: backgroundSizeAnimation2 2s ease-in-out;
}
@keyframes backgroundSizeAnimation2 {
  0% {
    background-size: 200% 200%;
  }

  100% {
    background-size: 100% 100%;
  }
}
.heading-m-cbs {
  background: linear-gradient(-45deg, #b8dff0, #b8dff0);
  /* color: black; */
  padding: 5px 10px;
  font-weight: 500;
  color: white;
  font-size: 13px;
  border-radius: 5px;
  width: 110px;
  margin-bottom: 0px;
}
.card-vd-top-m.no-bg-card {
  background: linear-gradient(-45deg, #b8dff0, #b8dff0); /* color: black; */
  padding: 30px;
}
.landing-logo-no-10 .ant-space-item {
  color: white !important;
  font-weight: 500;
}
.landing-logo-no-10 .ant-space-item .anticon-down {
  margin-right: 15px;
}
.landing-logo-no-10 b {
  font-weight: 500;
}

.new-landing-c .text-sixe0card {
  max-width: 600px;
  margin-top: 20px;
  text-align: center;
  color: white;
}
.text-area-landing-ctb {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-area-landing-ctb h1 {
  color: white;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px #ffffffcf;
}
.text-coloredc- {
  text-shadow: 1px 1px #06b4ff;
  color: white;
}
.btm-btns-tb-2 .my-lbutton svg {
  font-size: 22px;
}
.-t-tbn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 10px;
  color: white;
  text-shadow: 2px 2px rgb(116, 116, 116);
  text-transform: uppercase;
  cursor: pointer;
  border: solid 2px black;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 17px;
  background-color: hsl(49deg 98% 60%);
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
  margin: 0px 20px;
}

.-t-tbn:active {
  transform: scale(0.9);
  transition: all 100ms ease;
}

.-t-tbn svg {
  transition: all 0.5s ease;
  z-index: 2;
}

.-t-tbnplay {
  transition: all 0.5s ease;
  transition-delay: 300ms;
}

.-t-tbn:hover svg {
  transform: scale(3) translate(50%);
}

.-t-tbnnow {
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  transition: all 0.5s ease;
  z-index: 2;
}

.-t-tbn:hover .-t-tbnnow {
  transform: translateX(10px);
  transition-delay: 300ms;
}

.-t-tbn:hover .-t-tbnplay {
  transform: translateX(200%);
  transition-delay: 300ms;
}
.btm-btns-tb- {
  display: flex;
  flex-direction: column;
  align-items: center;
  \width: 100%;
}
.btm-btns-tb-2 {
  display: flex;
}
.control-bf-left {
  overflow-y: auto;
}
.sign-in-form-input.search-input-c {
  margin: 0 auto;
  width: 230px;
}
.my-lbutton.green-my-lb {
  background-image: linear-gradient(to right, rgb(2, 130, 2), rgb(0, 200, 0));
}
.my-lbutton.orange-my-lb {
  background-image: linear-gradient(to right, rgb(179, 117, 4), orange);
}
.my-lbutton.red-my-lb {
  background-image: linear-gradient(to right, rgb(208 2 2), #ff1c1c);
}

.my-lbutton.orange-my-lb .ani-for-cirlce,
.my-lbutton.green-my-lb .ani-for-cirlce {
  animation: rotate 2s linear infinite;
}
.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
.circle {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.header-cards-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
}
.header-cards-btn .hover-card-b {
  margin: 0px 10px 20px 10px;
}
.filter-box-r {
  display: flex;
}
.tele-cards.landing-tele-card {
  padding-top: 0px;
  position: relative;
  z-index: 50;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tele-cards.landing-tele-card .tele-card {
  background: linear-gradient(to right, rgb(20, 30, 48), rgb(36, 59, 85));
  box-shadow: rgb(0, 0, 0, 0.7) 5px 10px 50px, rgb(0, 0, 0, 0.7) -5px 0px 250px;
}
.tele-cards.landing-tele-card .tele-c-desc {
  font-size: 12px;
}
.card-giv-ce {
  width: 30px;
  margin-right: 10px;
}
.arrow-c-mcs {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.arrow-c-mcs svg {
  font-size: 14px !important;
  position: relative;
  right: 0px;
  transition: 0.7s;
  margin-top: -10px;
}
a {
  text-decoration: none;
}
.tele-cards.landing-tele-card .tele-card:hover .arrow-c-mcs svg {
  position: relative;
  right: -10px;
  transition: 0.7s;
}

.input-main-search-ccs {
  display: flex;
  flex-direction: column;
  gap: 7px;
  position: relative;
  color: white;
}
.center-c-flx {
  display: flex;
  justify-content: center;
}
.input-main-search-ccs .-stplabel {
  font-size: 15px;
  padding-left: 15px;
  position: absolute;
  top: 13px;
  transition: 0.3s;
  pointer-events: none;
}

.-stpinput {
  width: 250px;
  height: 45px;
  border: none;
  outline: none;
  padding: 0px 7px;
  border-radius: 6px;
  color: #fff;
  font-size: 15px;
  background-color: #3a3b3c;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4)
}
.iox-exp{
  position: absolute;
  right: 10px;
  top: 8px;
  font-size: 25px;
  animation: colorChange 2s infinite;
  /* background-color: white; */
  border-radius: 50%;

}
@keyframes colorChange {
  0% {
      color: yellow;
  }
  50% {
      color: gold;
  }
  100% {
      color: yellow;
  }
}

.-stpinput:focus {
  border: 2px solid transparent;
  color: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 1),
    -1px -1px 6px rgba(255, 255, 255, 0.4), inset 3px 3px 10px rgba(0, 0, 0, 1),
    inset -1px -1px 6px rgba(255, 255, 255, 0.4);
}

/* .input-main-search-ccs .-stpinput:valid ~ .-stplabel, */
.input-main-search-ccs .-stpinput:focus ~ .-stplabel {
  transition: 0.3s;
  padding-left: 2px;
  transform: translateY(-35px);
}

/* .input-main-search-ccs .-stpinput:valid, */
.input-main-search-ccs .-stpinput:focus {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 1),
    -1px -1px 6px rgba(255, 255, 255, 0.4), inset 3px 3px 10px rgba(0, 0, 0, 1),
    inset -1px -1px 6px rgba(255, 255, 255, 0.4);
}
.btm-btns-tb-2 .my-lbutton{
  padding: 5px 10px;
}
@media screen and (max-width: 680px) {
  .my-lheading {
    font-size: 3rem;
  }
  .hide-mobile {
    display: none;
  }
  .show-mobile {
    display: flex;
  }
  .video-container.full-height > div {
    padding: 0px;
  }
  .testing-animation-cads .card-vd-top-m h5 {
    font-size: 19px;
    margin-bottom: 10px;
  }
  .text-sixe0card {
    font-size: 14px;
  }
  .card-vd-top-m.no-bg-card {
    padding: 15px;
    width: 265px;
  }
  .show-mobile .card-vd-top-m {
    margin-right: 0px !important;
  }
  .testing-animation-cads {
    left: 5px;
    bottom: 5px;
  }
  .bottom-right-pop.show-mobile {
    bottom: 0px;
  }
  .control-bf-left .bottom-left-popup {
    right: 10px !important;
    left: auto !important;
  }
  .my-lbutton {
    padding: 5px 10px;
  }
  .text-area-landing-ctb h1 {
    font-size: 24px;
  }
  .tele-cards.landing-tele-card .tele-card {
    width: 100%;
    margin-bottom: 15px;
  }
  .mian-cntr-img {
    width: 100%;
  }
  .abt-r-txt-c{
    margin-top: 30px;
  }
}
