.ant-slider .ant-slider-rail {
  background-color: #a4a4a4;
}
.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #bedbff;
}
.ant-slider .ant-slider-track {
  background-color: #bedbff;
}
.slider-bx-c {
  display: flex;
  align-items: flex-start;
}
.ant-input-number .ant-input-number-input {
  background-color: #0c0f14;
}
.ant-input-number .ant-input-number-input {
  color: white;
}
.flex-end-icons- {
  margin-top: "10px";
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
}
.flex-end-icons- svg {
  font-size: 20px;
  margin-left: 10px;
  margin-top: 10px;
  color: white;
}
.tags-area-topic {
  display: flex;
  padding-bottom: 5px;
  width: 100%;
  flex-wrap: wrap;
}
.slider-image-si img {
  height: 300px;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
}
.sumilation-main-d {
  padding: 60px 10px 20px 10px;
  height: 100vh;
  overflow-y: auto !important;
}
.chat-video-right {
  height: 85vh;
}

.simu-card-t-i {
  margin-bottom: 10px;
  font-size: 18px;
}
.simu-card-t-i svg {
  font-size: 30px;
  padding: 3px;
  margin-right: 10px;
}
.simu-main-cards-c {
  padding: 10px 0px;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.simu-main-cards-c .hover-card-b {
  height: 100%;
  align-items: flex-start;
  color: white;
  text-transform: none;
}
.simu-main-cards-c .ant-row {
  max-width: 680px;
  width: 100%;
}
.space-b-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ling-text-header {
  text-decoration: none;
  color: white;
  margin: 0px 5px;
  font-weight: 500;
}
.ling-text-header:hover {
  transition: 0.5s;
  color: #bedbff;
}

.live-e-card {
  background: transparent;
  box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
  position: relative;

  height: 300px;
  border-radius: 10px;
  overflow: hidden;
}
.live-e-card .ant-typography {
  font-size: 18px;
  color: white;
  margin: 0px 5px;
}

.live-wave {
  position: absolute;
  width: 540px;
  height: 700px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -50%;
  margin-top: -70%;
}
.green-wave.live-wave {
  background: linear-gradient(744deg, #1aac00, #20a200 60%, #00eb76);
}
.orange-wave.live-wave {
  background: linear-gradient(744deg, #ac9500, orange 60%, #8d7b08);
}

.yellow-wave.live-wave {
  background: linear-gradient(744deg, #717171, yellow 60%, rgb(131, 114, 2));
}

.live-icon {
  font-size: 50px;
  margin-bottom: 10px;
}

.live-infotop {
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 5.6em;
  left: 0;
  right: 0;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.live-name {
  font-size: 14px;
  font-weight: 100;
  position: relative;
  top: 1em;
  bottom: 0;
  text-transform: lowercase;
}

.live-wave:nth-child(2),
.live-wave:nth-child(3) {
  top: 210px;
}

.live-playing .live-wave {
  border-radius: 40%;
  animation: wave 3000ms infinite linear;
}

.live-wave {
  border-radius: 40%;
  animation: wave 55s infinite linear;
}

.live-playing .live-wave:nth-child(2) {
  animation-duration: 4000ms;
}

.live-wave:nth-child(2) {
  animation-duration: 50s;
}

.live-playing .live-wave:nth-child(3) {
  animation-duration: 5000ms;
}

.live-wave:nth-child(3) {
  animation-duration: 45s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.bottom-icnx-s {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.time-counter-r {
  display: flex;
  justify-content: center;
}
.time-counter-r span {
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.512);
  border-radius: 5px;
}

.c3-icons-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  z-index: 999;
  align-items: center;
}

.c3-icons-container .c3-svg {
  stroke: black;
  color: black;
  font-size: 20px;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.c3-icons-container .c3-svg:hover {
  transform: scale(1.1);
  stroke: black;
  cursor: pointer;
}

.c3-icons-container .c3-twitter {
  fill: white;
}

.c3-icons-container .c3-twitter:hover {
  fill: black;
  stroke: none;
}
.c3-icons-container .ant-tag {
  cursor: pointer;
}

.c3-logo {
  width: 50px;
  padding: 1rem;
}

.c3-logo .c3-svg {
  fill: white;
}

.c3-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-weight: bold;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
}
.c3-title span {
  font-size: 13px;
}
.c3-box {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: rgba(66, 66, 66, 0.349) 5px 0px 10px 0px inset;
  transition: all 0.5s ease-in-out;
  display: grid;
  place-content: center;
  padding: 2rem 2rem 3rem 2rem;
  border: 1px solid black;
}

.c3-box .c3-content {
  position: relative;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.5s ease-in-out 0.3s;
}

.c3-box .c3-content .c3-box-title {
  display: block;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.content-card-mainc {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  height: 100%;
}

.c3-box .c3-content .c3-box-text {
  color: rgba(255, 255, 255, 0.738);
  font-size: 0.8rem;
}

.c3-box:hover {
  right: 0;
  top: 0;
  border-radius: 0;
  border: 1px solid transparent;
  box-shadow: rgba(66, 66, 66, 0) 5px 0px 10px 0px inset;
}

.c3-box:hover .c3-content {
  transform: translate(0, 0);
  opacity: 1;
}

.c3button {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica, "sans-serif";
  transition: all 0.2s ease-in;
  padding: 10px 20px;
  border-radius: 100px;
  background: linear-gradient(-45deg, white 0%, white 100%);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 0px 10px;
}
.active-live-c.c3button {
  background: linear-gradient(-45deg, #b3cae7 0%, #bedbff 100%);
}
.c3button:hover {
  cursor: pointer;
}

.c3button > svg {
  width: 34px;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.c3button:hover svg {
  transform: translateX(5px);
}

.c3button:active {
  transform: scale(0.95);
}

.s3-tabs-c {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}
.flex-end-top-c {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.flex-end-top-c .hover-card-b svg {
  margin-left: 5px;
  margin-right: 5px;
}
.flex-end-top-c .hover-card-b:hover svg {
  margin-left: 10px;
  margin-right: 0px;
  transition: 0.5s;
}
.together-fields-c {
  display: flex;
}
.date-title-c {
  margin-bottom: 60px;
}
.date-title-c h3 {
  color: #fbc97e;
  background-color: rgb(255 255 255 / 10%);
  display: inline;
  padding: 10px;
  position: relative;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
}
.date-title-c h3 svg {
  margin-right: 10px;
  color: #bedbff;
  /* position: absolute; */
  /* left: 10px; */
}
.date-title-c .ant-row {
  margin-top: 20px;
}

.chat-video-right {
  /* border: 1px solid rgb(255, 255, 255) !important; */

  background-color: rgba(255, 255, 255, 0.131);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  z-index: 10;
  color: white;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: flex-start;
  /* background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}
.chat-close-icn svg {
  position: absolute;
  right: 10px;
  top: 10px;
}
.chat-close-icn svg:hover {
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.4s;
  color: #bedbff;
  background-color: black;
}
.ant-col {
  transition: all 0.4s ease-in-out;
}
.chat-open-icn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  padding: 5px;
  border-radius: 5px;
  color: #bedbff;
  background-color: black;
  display: flex;
  align-items: center;
}
.inst-date-c {
  margin-top: 10px;
  padding: 20px;
  border-radius: 15px;

  /* background-color: white; */
}

.inst-date-c b {
  text-align: center;
  width: 100%;
}
.date-bx-righ {
  display: flex;
  justify-content: flex-end;
  width: 150px;
  text-align: right;
  font-size: 12px;
}
.no-f-no-h .ant-modal-footer {
  display: none;
}
.chat-open-icn:hover {
  background: linear-gradient(-45deg, #bedbff 0%, #4b6c94 100%);

  color: black;
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.4s;
}
.chat-video-right .c3button {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
}
.chat-bx-input-c .ant-input {
  background-color: transparent;
  border: 1px solid yellow;
color: white;
  border-radius: 15px;
}
.chat-bx-input-c .ant-input::placeholder {
  color: rgb(132, 132, 132);
}
.advertize-con {
  padding: 10px;
  margin: 10px 0px;
  background-color: rgba(255, 255, 255, 0.1);
}
.active-goals-tc256 {
  height: 70vh;
  overflow-y: auto;
}

.ant-picker {
  color: white !important;

  background-color: transparent;
  border-radius: 0px;
}
.ant-picker .ant-picker-input > input {
  color: white !important;
}
.ant-picker .ant-picker-suffix {
  color: white !important;
}
.ant-picker .ant-picker-input > input::placeholder {
  color: white !important;
}
.date-session-c {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.date-session-c2 {
  margin-top: 20px;
}
.ant-input-number {
  color: white !important;
  background-color: transparent;
  border-radius: 0px;
}
.ant-input-number::placeholder {
  color: white;
}
.cell-call-img-ac {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  z-index: 20;
}
.call-icon-c {
  color: lime;
  position: absolute;
  right: 40px;
  top: 30px;
  z-index: 30;
  cursor: pointer;
}
.call-icon-c:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
.audio-hidden-c {
  transform: scale(0);
}
.animated-bg-live {
  font-size: 25px;
  animation: colorChange 5s infinite;
}
@keyframes colorChange {
  0% {
    color: lime;
  }
  50% {
    color: white;
  }
  100% {
    color: lime;
  }
}

@keyframes blinkCursor {
  50% {
    border-right-color: transparent;
  }
}

@keyframes typeAndDelete {
  0%,
  10% {
    width: 0;
  }
  45%,
  55% {
    width: 6.2em;
  } /* adjust width based on content */
  90%,
  100% {
    width: 0;
  }
}
.text2 {
  margin-top: 30px;
}
.terminal-loader {
  border: 0.1em solid #333;
  background-color: #1a1a1a;
  color: #0f0;
  font-family: "Courier New", Courier, monospace;
  font-size: 1em;
  padding: 1.5em 1em;
  width: 400px;
  margin: 100px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.terminal-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1.5em;
  background-color: #333;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0 0.4em;
  box-sizing: border-box;
}

.terminal-controls {
  float: right;
}

.control {
  display: inline-block;
  width: 0.6em;
  height: 0.6em;
  margin-left: 0.4em;
  border-radius: 50%;
  background-color: #777;
}

.control.close {
  background-color: #e33;
}

.control.minimize {
  background-color: #ee0;
}

.control.maximize {
  background-color: #0b0;
}

.terminal-title {
  float: left;
  line-height: 1.5em;
  color: #eee;
}

.text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.2em solid green; /* Cursor */
  animation: typeAndDelete 4s steps(20) infinite,
    blinkCursor 0.5s step-end infinite alternate;
  margin-top: 1.5em;
}
.wheel {
  position: relative;
  animation: rotateWheel 4s linear infinite; /* Calls the animation */
}

@keyframes rotateWheel {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.my-lbuttons {
  margin-top: 20px;
  width: 350px;
  display: flex;
  justify-content: space-around;
}

.my-lbutton {
  padding: 10px 20px;
  font-size: 1rem;
  background: linear-gradient(-45deg, #bedbff 0%, #7eb0ec 100%);
  color: BLACK;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 500;
}
/* .my-lbutton.gold-btn-c {
  background: linear-gradient(-45deg, rgb(252, 237, 156) 0%,gold 100%);
color: black;
} */
.my-lbutton.table-lb-btn{
  padding: 5px 10px;
  display: flex;
  align-items: center;
}
.my-lbutton.table-lb-btn svg{
  margin-left: 5px;
  font-size: 12px;
}
.my-lbutton.table-lb-btn:hover svg{
  position: relative;
  right: -5px;
  transform: 1s;
}
.link-click-cs{
  max-width: 200px;
}
.max-150h-c {
  max-height: 120px;
  overflow-y: auto;
  margin-top: 10px;
  text-align: left;
}
.vector-r-card {
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  width: 100%;
}
.vector-r-card img {
  width: 100%;
  max-width: 200px;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
  border-radius: 5px;
}
.active-v-c.vector-r-card {
  background-image: linear-gradient(to right, black, black);
  color: white;
  border: 1px solid gold;
}
.chat-video-right.vec-r {
  border-color: transparent;
}
.btn-top-20 {
  position: relative;
  top: -20px;
}
.s3-tabs-c2 {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-video-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
}

/* Style for the ReactPlayer element */
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* Overlay styles */
.custom-overlay {
  position: absolute;

  /* background-color: rgba(0, 0, 0, 0.5); */
  color: white;
  z-index: 100;
  margin: 10px;
  border-radius: 10px;
}
.bg-o-bbr.custom-overlay {
  padding: 20px;
  background: #4a4a4a;
}
/* Top left overlay */
.top-left-overlay {
  top: 10px;
  left: 10px;
}

/* Bottom left overlay */
.bottom-left-overlay {
  bottom: 10px;
  left: 10px;
}

/* Bottom right overlay */
.bottom-right-overlay {
  bottom: 0px;
  right: 0px;
}

/* Style for the overlay button */
.custom-overlay-button {
  background-color: black;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 7px;
}

.b-r-main-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.b-r-main-c1 {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #898989;
}
.b-r-main-c1 .icon-d-mmn {
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;

  background-color: #faf9f6;
  color: black;
}
.add-peron-main-c .my-lbutton {
  padding: 5px 10px;
}
.custom-video-container .my-lbutton {
  background-image: none;
  background-color: #faf9f6;
  color: black;
}
.people-d-bv {
  /* border: 1px solid white; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  color: white;
  background-image: linear-gradient(90deg, black, #062517);
}
.normal-topic-t {
  background: linear-gradient(-45deg, #090909 0%, #353535 100%);
  color: white;
  border-color: transparent;
  margin: 5px;
  cursor: pointer;
  border-radius: 15px;
}
.normal-topic-t.active-topi-t {
  background: linear-gradient(-45deg, rgb(252, 237, 156) 0%, gold 100%);
  color: rgb(0, 0, 0);
}
.loading-heigh-div h5 {
  text-align: center;
  margin-top: 30px;
}
.tele-main-card-c h5 {
  text-align: center;
  font-size: 30px;
}
.people-d-bvc {
  margin-top: 10px;
  padding: 20px;
  height: 100%;
  margin-top: 20px;
  /* border-radius: 15px; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
  /* background-color: white; */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid rgb(255, 255, 255); */
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.131);
}
.people-d-bvc23 {
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  max-width: 500px;
}
.share-icon-c-br .ant-typography .ant-typography-copy-icon-only {
  color: white;
  font-size: 20px;
}
.people-d-bv.active-p-bv {
  background: linear-gradient(-50deg, #0000, green);
}
.people-d-bv.active-p-pub {
  background: linear-gradient(-50deg, rgb(43, 50, 0), rgb(237, 202, 1));
}
.ins-watch-c.active-p-pen {
  background: linear-gradient(-45deg, orange, yellow, orange);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.desc-p-crdjjkl {
  color: black !important;
}
.share-icon-c-br {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.ins-watch-c:hover .show-hover {
  display: inline;
}
.ins-watch-c:hover .hide-hover {
  display: none;
}
.modal-heigh-login .login-page {
  height: auto;
}
.ins-watch-c {
  padding: 20px;
  margin-bottom: 20px;
  background-color: black;
  /* #e0e0e0 */
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid transparent;
}
.ins-watch-c.active-p-pen .ant-typography{
  color: black;

}
.ins-watch-c .ant-typography{
  color: white;

}
.ins-watch-c.score-h-s {
  background-color: white;
  color: black;
}

.gold-button.ant-btn-default {
  background: linear-gradient(-50deg, #f5fca8, gold);
  border-color: transparent !important;
}
.gold-button.ant-btn-default:hover {
  background: linear-gradient(-50deg, gold, gold);
  border-color: transparent !important;
}
.remover-person-rg {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.circular-buttons-c .my-lbutton {
  border-radius: 50%;
}
.loader-video-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.loader-video-img img {
  width: 100px;
  height: 100px;

  animation: blinkImg 1s infinite alternate; /* Using the 'blink' animation */
}

/* Keyframes for the blink animation */
@keyframes blinkImg {
  0% {
    opacity: 1; /* Fully visible */
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);

    opacity: 0; /* Fully transparent */
  }
}

.notifications-container {
  width: 100%;
  height: auto;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.chat-video-right.vec-r .tabs-c-link.ant-btn-primary {
  background: linear-gradient(-45deg, rgb(252, 237, 156) 0%, gold 100%);  /* Define your gradient colors */
  -webkit-background-clip: text; /* For Safari */
  background-clip: text;
  border-color: transparent;
  font-weight: 600;
  color: transparent; /* Hide the original text color */
}

.chat-video-right.vec-r .tabs-c-link.ant-btn-primary:hover {
  color: transparent; /* Hide the original text color */
  background-image: linear-gradient(
    to right,
    green,
    #0c3c26
  ) !important; /* Define your gradient colors */
  -webkit-background-clip: text; /* For Safari */
  background-clip: text;
  border-color: transparent !important;
}
.tabs-c-link.ant-btn-link {
  color: rgb(188, 188, 188);
}
.tabs-c-link.ant-btn-link:hover {
  color: white;
}

.gradient-text-title {
  background-image: linear-gradient(to right, white, rgb(183, 183, 183));
  /* Define your gradient colors */
  -webkit-background-clip: text; /* For Safari */
  background-clip: text;
  display: inline;
  color: transparent; /* Hide the original text color */
}

.dad-joke {
  padding: 1rem;
  border-radius: 0.375rem;
  background-image: linear-gradient(to right, rgb(237, 237, 237), #b3b3b3);
}

.dad-joke-svg {
  color: #1a202c;
  width: 1.25rem;
  height: 1.25rem;
}

.dad-joke-prompt-wrap {
  margin-left: 0.75rem;
}

.dad-joke-prompt-heading {
  font-weight: bold;
  color: rgb(57, 55, 54);
}

.dad-joke-prompt {
  margin-top: 0.5rem;
  color: rgb(57, 55, 54);
}

.dad-joke-button-container {
  display: flex;
  margin-top: 0.875rem;
  margin-bottom: -0.375rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.dad-joke-button-main {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #000000;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  border-radius: 0.375rem;
  border: none;
  margin-bottom: 10px;
}

.dad-joke-button-main:hover {
  background-color: #258002;
}
.ins-bewl-v {
  text-align: "justify";
  font-size: "16px";
  font-weight: 500;
  margin: 20px 40px;
}
.vector-r-card.ins-watch-c.active-card-ses {
  background-image: linear-gradient(to right, black, black);
  border-color: #004cc618;
  color: white;
}

.my-container-100v {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.tele-cards {
  display: flex;
  gap: 15px;
  flex-direction: column;
  padding: 20px;
  margin-top: 20px;
  justify-content: center;
}
.tele-cards > div {
  display: flex;
  gap: 15px;
  justify-content: center;
}
.hr-color-gr {
  color: #1f2836;
  opacity: 1;
}
.back-icn.hover {
  color: white;
}
.modal-heigh-login .sign-in-form-input {
  padding: 0px 10px;
  border: 1px solid #828181;
  height: 40px;
  border-radius: 5px;
}
.modal-heigh-login .sign-in-right-content {
  padding: 0px;
}
.modal-heigh-login .sign-in-form-field-label {
  font-size: 14px;
}
.modal-heigh-login .fonr-lgn-c {
  padding: 20px 0px 20px 0px;
}
.two-input-together {
  display: flex;
}
.two-input-together .sign-in-form-input {
  width: 200px;
}
.two-input-together .form-group {
  display: flex;
  flex-direction: column;
}
.two-input-together .margin-l-2- {
  margin-left: 15px;
}
.my-lbutton.fle-x-c {
  display: flex;
  align-items: center;
}
.my-lbutton.fle-x-c svg{
  margin-right: 10px;
}
.circle-nmbr {
  border: 1px solid rgba(255, 255, 255, 0.458);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  margin-left: 5px;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 1, 0.366); */
}
.tele-cards .tele-card.heigth-tele-card {
  width: 250px;
  font-size: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.tele-c-desc {
  margin-top: 20px;
  max-height: 145px;
  overflow-y: auto;
}
.tele-cards .tele-card.heigth-tele-card svg {
  font-size: 30px;
  margin-right: 10px;
}
.tele-cards .tele-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  /* height: 100px; */
  width: 200px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 400ms;
  padding: 10px;
  background-image: linear-gradient(to right, black, black);
}
.add-percon-mains {
  min-height: 75vh;
}
.tele-cards .tele-card p.tele-tip {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 0px;
}
.brand-modal .ant-modal-close-x {
  color: white;
}
.tele-cards .tele-card p.tele-second-text {
  font-size: 1em;
}

.tele-cards .tele-card:hover {
  transform: scale(1.03, 1.03);
}

.tele-cards:hover > .tele-card:not(:hover) {
  filter: blur(5px);
  transform: scale(0.9, 0.9);
}

.score-h-s h6 {
  display: flex;
  justify-content: space-between;
}
.container-my-20 {
  padding: 0px 50px;
}
.tabs-c-link.ant-btn-link {
  padding: 4px 6px;
}
.leave-btn-css {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  top: -30px;
}
@media screen and (max-width: 768px) {
  .chat-video-right {
    height: 100%;
  }
  .container-my-20 {
    padding: 0px 10px;
  }
  .ins-watch-c img {
    margin: 0px 10% 10px 10%;
    width: 80%;
  }
  .video-container-round .gradient-text-title {
    font-size: 17px;
    margin-bottom: 10px !important;
  }
  .container-fluid.my-container-flui .logo-img-bc {
    width: 35px;
  }
  .navbar.navbar-expand-lg.navbar-light {
    padding: 0px;
  }
  .container.my-container-100v {
    padding-top: 40px !important;
  }
  .chat-video-right {
    padding: 10px 20px;
  }
  .tab-r-vc {
    margin-bottom: 0px;
  }
  .ins-watch-c {
    padding: 10px;
  }
  .max-150h-c {
    max-height: 80px;
    margin-bottom: 5px;
  }
}
